/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-underscore-dangle */
import { NetworkService } from './../network.service';
import { SoundsService } from './../sounds.service';
import { Settings } from './settings.type';
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take, tap, timeout } from 'rxjs/operators';
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root',
})
export abstract class SettingsService {
  public static settings: Settings;
  public _settingData = new Subject<Settings>();
  public _appState = new Subject<string>();

  bluetooth = true;
  wifi = false;
  shiftSub: Subscription;
  userSub: Subscription;
  userId: string;
  collectionStartTime: Date;
  networkSub: any;
  networkType: string;

  get settingData() {
    return this._settingData.asObservable();
  }

  get appState() {
    return this._appState.asObservable();
  }

  constructor(
    private http: HttpClient,
    private soundsService: SoundsService,
    private networkService: NetworkService,
    ) {
    /*
      DEFAULT SETTINGS
    */
    const intervalDefault = 60 * 60 * 2; //seconds * minutes * hours
    const durationDefault = 120;
    const defaultCount = 10;
    const defaultProgress = 0;

    const dafaultStartTimeHour = 8;
    const dafaultStartTimeMinute = 0;
    const dafaultStopTimeHour = 20;
    const dafaultStopTimeMinute = 0;

    const globalAlertTriggerPct = 50;
    const globalAlertTriggerThreshold = 70;

    const localAlertTriggerPpi = 150;
    const localAlertTimeThreshold = 40;

    const uploadContinuosMaps = false;
    const uploadContinuosMetrics = false;
    const uploadIntervalMinutes = 5;

    const showNotifications = false;
    const dailyNotifications = false;
    const soundNotifications = false;
    const soundOnComplete = false;
    const soundOnNotification = false;
    const showProgress = false;
    const showDetails = false;
    const showPPIDetails = false;

    const sampleRate = 10;
    const showShiftTimer = false;
    const inputRotation = 'Front';
    const selectedTheme = 'light';
    const selectedMapColor = 'default';
    const shiftsPerHour = 4;
    const detectShifts = true;
    const editBy = 'patient';

    const pressureReliefTechnique = 'manual';
    const detectionDuration = 5;
    const detectionPPIThreshold = 75;
    const detectionPPICount = 5;
    const performCalibration = true;

    const showSettings = {
      weightShift: true,
      time: true,
      color: true,
      notification: true,
      dataCollection: true,
      mapSettings: true,
      globalPressure: true,
      localPressure: true,
      recordingsTab: true,
      graphsTab: true,
      pressureReliefTechnique: true,
    };

    const calibrationSections = [  //Default calibration sections
      { name: 'Center', id: 'center', checked: true, bounds: { x: 4, y: 4, width: 8, height: 8 } },
      { name: 'Left', id: 'left', checked: true, bounds: { x: 0, y: 0, width: 6, height: 16 } },
      { name: 'Right', id: 'right', checked: true, bounds: { x: 10, y: 0, width: 6, height: 16 } },
      { name: 'Front', id: 'front', checked: true, bounds: { x: 0, y: 0, width: 16, height: 6 } },
      { name: 'Back', id: 'back', checked: true, bounds: { x: 0, y: 7, width: 16, height: 9 } },
    ];

    this.networkSub = this.networkService.networkTypeObs.subscribe((networkType) => {
      this.networkType = networkType;
    });


    const completeSoundPath = this.soundsService.defaultSoundPath();
    const notificationSoundPath = this.soundsService.defaultNotificationPath();

    const serverURL = '';

    this.get('authData').then((authData) => {
      try {
        authData = JSON.parse(authData.value);

        SettingsService.settings.userId = authData.id;
        this._settingData.next(SettingsService.settings);
      } catch {
      }
    });

    this.get('collection_start').then((collectionStart) => {
      let collectionStartString = new Date().getTime().toString();
      try {
        if (!collectionStart || !collectionStart.value) {
          // default value
          this.set('collection_start', collectionStartString);
        } else {
            collectionStartString = collectionStart.value;
        }
        this.collectionStartTime = new Date(+collectionStartString);
        SettingsService.settings.collectionStartTime = new Date(+collectionStartString);
      } catch {
      }
    });

    const booleanSettings = [
      {name: 'soundOnComplete', value: soundOnComplete},
      {name: 'soundOnNotification', value: soundOnNotification},
      {name: 'soundNotifications', value: soundNotifications},
      {name: 'dailyNotifications', value: dailyNotifications},
      {name: 'showDetails', value: showDetails},
      {name: 'showPPIDetails', value: showPPIDetails},
      {name: 'showProgress', value: showProgress},
      {name: 'showNotifications', value: showNotifications},
      {name: 'uploadContinuosMaps', value: uploadContinuosMaps},
      {name: 'uploadContinuosMetrics', value: uploadContinuosMetrics},
      {name: 'showShiftTimer', value: showShiftTimer},
      {name: 'detectShifts', value: detectShifts},
      {name: 'performCalibration', value: performCalibration},
    ];

    for (const boolSetting of booleanSettings) {
      this.get(boolSetting.name).then((bool) => {
        if (!bool || !bool.value) {
          // default value
          this.set(boolSetting.name, boolSetting.value);
        } else {
          boolSetting.value = bool.value === 'true';
        }
        SettingsService.settings[boolSetting.name] = boolSetting.value;
        this._settingData.next(SettingsService.settings);
      });
    }

    const stringSettings = [
      {name: 'serverURL', value: serverURL},
      {name: 'completeSoundPath', value: completeSoundPath},
      {name: 'notificationSoundPath', value: notificationSoundPath},
      {name: 'inputRotation', value: inputRotation},
      {name: 'selectedTheme', value: selectedTheme},
      {name: 'selectedMapColor', value: selectedMapColor},
      {name: 'pressureReliefTechnique', value: pressureReliefTechnique},
    ];

    for (const stringSetting of stringSettings) {
      this.get(stringSetting.name).then((stringSet) => {
        if (!stringSet || !stringSet.value) {
          // default value
          this.set(stringSetting.name, stringSetting.value);
        } else {
          stringSetting.value = stringSet.value.toString();
        }
        SettingsService.settings[stringSetting.name] = stringSetting.value;
        this._settingData.next(SettingsService.settings);
      });
    }

    const timeSettings = [
      {name: 'dailyStartTime', hour: dafaultStartTimeHour, minute: dafaultStartTimeMinute, value: null},
      {name: 'dailyStopTime', hour: dafaultStopTimeHour, minute: dafaultStopTimeMinute, value: null},
    ];
    for (const timeSetting of timeSettings) {
      this.get(timeSetting.name).then((time) => {
        const today = new Date();
        if (!time || !time.value) {
          // default value
          const defaultTime = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            timeSetting.hour,
            timeSetting.minute
          );
          this.set(timeSetting.name, defaultTime.toISOString());
        } else {
          timeSetting.hour = new Date(time.value).getHours();
          timeSetting.minute = new Date(time.value).getMinutes();

          if (new Date(time.value) <= today) {
            // date is not today reset start time to be todays date
            const startTime = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              timeSetting.hour,
              timeSetting.minute
            );
            timeSetting.value = startTime;
            this.set(timeSetting.name, startTime.toISOString());
          }
        }

        SettingsService.settings[timeSetting.name] = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          timeSetting.hour,
          timeSetting.minute
        );
        this._settingData.next(SettingsService.settings);
      });
    }

    const numberSettings = [
      {name: 'shiftInterval', value: intervalDefault},
      {name: 'shiftDuration', value: durationDefault},
      {name: 'globalAlertTriggerPct', value: globalAlertTriggerPct},
      {name: 'globalAlertTriggerThreshold', value: globalAlertTriggerThreshold},
      {name: 'localAlertTriggerPpi', value: localAlertTriggerPpi},
      {name: 'localAlertTimeThreshold', value: localAlertTimeThreshold},
      {name: 'shiftDailyGoalCount', value: defaultCount},
      {name: 'uploadIntervalMinutes', value: uploadIntervalMinutes},
      {name: 'sampleRate', value: sampleRate},
      {name: 'shiftsPerHour', value: shiftsPerHour},
      {name: 'detectionDuration', value: detectionDuration},
      {name: 'detectionPPIThreshold', value: detectionPPIThreshold},
      {name: 'detectionPPICount', value: detectionPPICount},

    ];

    for (const numberSetting of numberSettings) {
      this.get(numberSetting.name).then((numSet) => {
          if (!numSet || !numSet.value) {
            // default value
            this.set(numberSetting.name, numberSetting.value);
          } else {
            numberSetting.value = +numSet.value;
          }
          SettingsService.settings[numberSetting.name] = numberSetting.value;
          this._settingData.next(SettingsService.settings);
        });
    }
    SettingsService.settings = {
      userId: this.userId,
      shiftInterval: intervalDefault, // In seconds
      shiftDuration: durationDefault, // In seconds
      shiftDailyGoalCount: defaultCount,
      shiftDailyGoalProgress: defaultProgress,
      showProgress,
      showNotifications,
      soundNotifications,
      dailyNotifications,
      soundOnComplete,
      soundOnNotification,
      showDetails,
      showPPIDetails,

      completeSoundPath,
      notificationSoundPath,
      serverURL,

      uploadContinuosMaps,
      uploadContinuosMetrics,
      uploadIntervalMinutes,

      sampleRate,
      showShiftTimer,
      inputRotation,
      selectedTheme,
      selectedMapColor,
      shiftsPerHour,
      detectShifts,
      calibrationSections,
      editBy,
      pressureReliefTechnique,
      detectionDuration,
      detectionPPIThreshold,
      detectionPPICount,
      showSettings,
      performCalibration,

      dailyStopTime: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        dafaultStopTimeHour,
        dafaultStopTimeMinute
      ),

      dailyStartTime: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        dafaultStartTimeHour,
        dafaultStartTimeMinute
      ),

      networkInterface:
        window.localStorage.getItem('Interface') || 'wifi',
      bluetoothDeviceAddress: window.localStorage.getItem(
        'bluetoothDeviceAddress'
      ),
      globalAlertTriggerPct,
      globalAlertTriggerThreshold,

      localAlertTriggerPpi,
      localAlertTimeThreshold,
      collectionStartTime: this.collectionStartTime,
    };

    this.getObject('calibrationSections').then((sections) => {
      if (!sections || sections.length < 1) {
        this.setObject('calibrationSections', calibrationSections);
        SettingsService.settings.calibrationSections = calibrationSections;
      } else {
        this.setObject('calibrationSections', sections);
        SettingsService.settings.calibrationSections = sections;
      }
    });

  }

  // set a key/value
  async set(key: string, value: any): Promise<any> {
    try {
      //send to servier
      const result = await Storage.set({ key, value });

      const recordAction = {
        type: 'settingChanged',
        data: { newValue: value, setting: key },
        date: new Date(),
      };
      return true;
    } catch (reason) {
      return false;
    }
  }
  // to get a key/value pair
  get(key: string): Promise<any> {
    try {
      const result = Storage.get({ key });
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      return null;
    }
  }
  // set a key/value object
  async setObject(key: string, object: Object) {
    try {
      const value = JSON.stringify(object);
      const result = await Storage.set({ key, value });
      return true;
    } catch (reason) {
      return false;
    }
  }


  // get a key/value object
  async getObject(key: string): Promise<any> {
    try {
      const result = await Storage.get({ key });
      if (result != null) {
        return JSON.parse(result.value);
      }
      return null;
    } catch (reason) {
      return null;
    }
  }
  // remove a single key value:
  async remove(key: string) {
    await Storage.remove({ key });
  }
  //  delete all data from your application:
  clear() {
    Storage.clear();
  }

  fetchSettingFromServer() {
    return this.http.get(SettingsService.settings.serverURL + '/settings')
    .pipe(
      take(1),
      timeout(10000), //timeout of 10 seconds
    );
  }

  sendSettingsToServer() {
    const settings = {
      settings_time: new Date().toISOString(),
      data: SettingsService.settings,
    };

    return this.http
      .post(SettingsService.settings.serverURL + '/settings', settings)
      .subscribe((resp) => {
      });
  }
}

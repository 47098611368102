import { NewPasswordPageModule } from './clinician/new-password/new-password.module';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { MatNativeDateModule } from '@angular/material/core';
import { AlertGraphModalPageModule } from './modals/alert-graph-modal/alert-graph-modal.module';
import { NgCalendarModule } from 'ionic2-calendar';
import { SettingInfoPopComponent } from './popovers/setting-info-pop/setting-info-pop.component';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpConfigInterceptor } from './httpConfig.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { DatePipe, CommonModule } from '@angular/common';

import { Media } from '@ionic-native/media/ngx';
import { File } from '@ionic-native/file/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { SettingsService } from './settings/settings.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


const config: SocketIoConfig = {
	url: '', // socket server url;
	options: {
    autoConnect: false,
		transports: ['websocket']
	}
};
  @NgModule({
    declarations: [AppComponent, SettingInfoPopComponent],
    imports: [
        SocketIoModule.forRoot(config),
        CommonModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        NgCalendarModule,
        GuidedTourModule,
        AlertGraphModalPageModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        NgxMatNativeDateModule,
        NewPasswordPageModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        BackgroundMode,
        DatePipe,
        File,
        Media,
        LocalNotifications,
        GuidedTourService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

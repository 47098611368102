import { BLANK_MAP } from './../../constants';
import { HistoryService, MapOutput } from './../../history.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, NavParams } from '@ionic/angular';
import { PressureMatService } from 'src/app/map-tab/pressure-mat/pressuremat.service';

@Component({
  selector: 'app-alert-graph-modal',
  templateUrl: './alert-graph-modal.page.html',
  styleUrls: ['./alert-graph-modal.page.scss'],
})
export class AlertGraphModalPage implements OnInit {
  @ViewChild(IonContent, {static: false}) content: IonContent;

  dateString: string;
  selectedAlert: any;

  modalTitle: string;
  modelId: string;
  mapData: MapOutput;
  mapDateString: string;
  isMapLoaded=false;
  alertData: any;
  triggerTime: number;
  triggerPct: number;

  constructor(private modalController: ModalController,
    private navParams: NavParams,
    private pressureMatService: PressureMatService,
    private historyService: HistoryService) {
      this.mapData = this.navParams.data.paramMap;
      this.alertData = this.navParams.data.paramAlertData;
    }

  ngOnInit() {
    console.table(this.navParams);
    this.triggerPct = this.alertData.triggerPct;
    this.triggerTime = this.alertData.triggerTime;
    //this.mapData = this.navParams.data.paramMap;
  }

  ionViewDidEnter(){
    this.isMapLoaded = false;
    this.onLoadMap(this.mapData);

  }

  async closeModal() {
    const onClosedData = 'Wrapped Up!';
    this.modalController.dismiss(onClosedData);
  }

  async onLoadMap(map) {
    const newMap = BLANK_MAP;
    newMap.values = map.values;
    this.dateString = map.date;
    this.mapDateString = new Date (map.date).toLocaleTimeString();
    this.pressureMatService.drawSingleMap(newMap, 'alert');
    this.setAlertRegions();
    this.isMapLoaded = true;
  }

  setAlertRegions() {
    this.alertData.regionData.forEach(alert => {
      this.pressureMatService.drawSingleCell('mapCanvas_alert', alert.x, alert.y, 'pink', 2, 2, 5);
    });
  }


  highlightRegion(alert) {
    this.selectedAlert = alert;
    this.setAlertRegions();
    this.pressureMatService.drawSingleCell('mapCanvas_alert', alert.x, alert.y, 'green', 2, 2, 5);
  }
}

/* eslint-disable no-underscore-dangle */
export class User {
  constructor(
    public id: string,
    private _token: string,
    public role: string,
  ) {}

  get token() {
    return this._token;
  }
}

import { PatientsService } from './../patient.service';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { take, tap } from 'rxjs/operators';
import { ClinicianManagementService } from '../clinician-management.service';

@Component({

  selector: 'app-new-password',
  templateUrl: './new-password.page.html',
  styleUrls: ['./new-password.page.scss'],
})
export class NewPasswordPage implements OnInit {
  @Input() modal: any;
  @Input() user: any;
  @Input() type: string;
  public newPasswordForm: UntypedFormGroup;
  showPassword = false;
  passwordToggleIcon = 'eye';

  constructor( private formBuilder: UntypedFormBuilder,
    private alertCtrl: AlertController,
    private patientService: PatientsService,
    private clinicianManagementService: ClinicianManagementService) {
      this.newPasswordForm = this.formBuilder.group({
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      }, { validators: this.checkPasswords });
    }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  };


  ngOnInit() {
    console.log(this.user);
  }

  showAlert(header: string, message: string) {
    this.alertCtrl.create({
      header,
      message,
      buttons: ['Ok']
    }).then(alertEl => {
      alertEl.present();
    });
  }

  onSubmit() {
    if (!this.newPasswordForm.valid) {
      return;
    }

    const newPassword = this.newPasswordForm.value.password;

    if (this.type === 'clinician') {
      this.clinicianManagementService.resetClinicianPassword(newPassword, this.user).pipe(
        take(1),
        tap(success => {
          this.showAlert(`New Password for ${this.user.email}`, `Password changed to: ${newPassword}`);
          this.newPasswordForm.reset();
          this.modal.dismiss();
        },
        error => {
          this.showAlert('Password Reset Failed', error.error);
          this.newPasswordForm.reset();
          this.modal.dismiss();
        }),
      ).subscribe();
    }
    if (this.type === 'patient') {
      this.patientService.resetPassword(newPassword, this.user.patientId).pipe(
        take(1),
        tap(success => {
          this.showAlert(`New Password for ${this.user.patientId}`, `Password changed to: ${newPassword}`);
          this.newPasswordForm.reset();
          this.modal.dismiss();
        },
        error => {
          this.showAlert('Password Reset Failed', error.error);
          this.newPasswordForm.reset();
          this.modal.dismiss();
        }),
      ).subscribe();
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;

    if (this.passwordToggleIcon === 'eye') {
      this.passwordToggleIcon = 'eye-off';
    } else {
      this.passwordToggleIcon = 'eye';
    }
  }


}

export abstract class CalendarHelpers{

  static getFirstDayOfWeek(currentDate: Date) {
    const firstDayofWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - currentDate.getDay()
    );
    return firstDayofWeek;
  }

  static getAllWeekDays(currentDate: Date) {
    const firstDay = this.getFirstDayOfWeek(currentDate);
    const daysOfWeek = [];
    for (let index = 0; index < 7; index++) {
      const day = new Date(
        firstDay.getFullYear(),
        firstDay.getMonth(),
        firstDay.getDate() + index
      );
      daysOfWeek.push(day);
    }
    return daysOfWeek;
  }

  static isToday(date: Date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  static getAllDaysInYear(year) {
    const days = [];
    for (let month = 1; month <= 12; month++) {
      const daysInMonth = new Date(year, month, 0).getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        days.push(new Date(year, month - 1, day));
      }
    }
    return days;
  }
}

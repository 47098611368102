import { Injectable } from '@angular/core';
import { CalculateMetricsService, MapDetails, RegionDetails } from '../calculate-metrics.service';

export interface PeriodAveragePPI {
  frontPPI: number;
  backPPI: number;
  leftPPI: number;
  rightPPI: number;
  q1PPI: number;
  q2PPI: number;
  q3PPI: number;
  q4PPI: number;
}

@Injectable({
  providedIn: 'root'
})
export class BetweenShiftService {
  mapDetails: MapDetails;
  currentMap: any;

  constructor(private calcService: CalculateMetricsService,) {
    this.resetMetrics();
  }

  resetMetrics() {
    this.mapDetails = new MapDetails();
    this.mapDetails.global = {
      name: 'global',
      averagePPI: 0,
      currentPPI: 0,
      ppiChange: 0,
      rollingPPIArray: [],
      ppiData: [],
    };
    this.mapDetails.front = {
      name: 'front',
      averagePPI: 0,
      currentPPI: 0,
      ppiChange: 0,
      dispersionIndex: 0,
      rollingPPIArray: [],
      ppiData: [],
    };
    this.mapDetails.back = {
      name: 'back',
      averagePPI: 0,
      currentPPI: 0,
      ppiChange: 0,
      dispersionIndex: 0,
      rollingPPIArray: [],
      ppiData: [],
    };
    this.mapDetails.left = {
      name: 'left',
      averagePPI: 0,
      currentPPI: 0,
      ppiChange: 0,
      dispersionIndex: 0,
      rollingPPIArray: [],
      ppiData: [],
    };
    this.mapDetails.right = {
      name: 'right',
      averagePPI: 0,
      currentPPI: 0,
      ppiChange: 0,
      dispersionIndex: 0,
      rollingPPIArray: [],
      ppiData: [],
    };
    this.mapDetails.q1 = {
      name: 'q1',
      averagePPI: 0,
      currentPPI: 0,
      ppiChange: 0,
      dispersionIndex: 0,
      rollingPPIArray: [],
      ppiData: [],
    };
    this.mapDetails.q2 = {
      name: 'q2',
      averagePPI: 0,
      currentPPI: 0,
      ppiChange: 0,
      dispersionIndex: 0,
      rollingPPIArray: [],
      ppiData: [],
    };
    this.mapDetails.q3 = {
      name: 'q3',
      averagePPI: 0,
      currentPPI: 0,
      ppiChange: 0,
      dispersionIndex: 0,
      rollingPPIArray: [],
      ppiData: [],
    };
    this.mapDetails.q4 = {
      name: 'q4',
      averagePPI: 0,
      currentPPI: 0,
      ppiChange: 0,
      dispersionIndex: 0,
      rollingPPIArray: [],
      ppiData: [],
    };
  }

  calculateBetweenShift(mapMatrix: any) {
    this.updateMapDetails(mapMatrix);
  }

  getPeriodPPI(): Promise<PeriodAveragePPI> {
    const periodPPI = {
      frontPPI: this.mapDetails.front.averagePPI,
      backPPI: this.mapDetails.back.averagePPI,
      leftPPI: this.mapDetails.left.averagePPI,
      rightPPI: this.mapDetails.right.averagePPI,
      q1PPI: this.mapDetails.q1.averagePPI,
      q2PPI: this.mapDetails.q2.averagePPI,
      q3PPI: this.mapDetails.q3.averagePPI,
      q4PPI: this.mapDetails.q4.averagePPI,
    };

    return new Promise(resolve => {
        resolve(periodPPI);
    });
  }

  async suggestShiftDirection() {
    const periodPPI = await this.getPeriodPPI();
    return periodPPI;
  }

  resetBetweenShiftMetrics() {
    this.resetMetrics();
  }

  calculateRegionDetails(
    mapDetailsRegion: RegionDetails,
    matrix,
    globalMatrix?) {

    const peakPressureData = this.calcService.getPeakPressureIndexMatrix(matrix);
    mapDetailsRegion.ppiData.push(peakPressureData);
    const peakPressure = peakPressureData.ppi || 0;
    mapDetailsRegion.rollingPPIArray.push(peakPressure);

    mapDetailsRegion.currentPPI = Math.round(peakPressure);
    mapDetailsRegion.averagePPI = this.calcService.calculate30MinuteAverage(mapDetailsRegion.rollingPPIArray);
    mapDetailsRegion.ppiChange = this.calcService.checkRiseInPressure(
      mapDetailsRegion.name,
      peakPressure,
      mapDetailsRegion.rollingPPIArray,
      this.currentMap,
      true
    ) || 0;

    if (mapDetailsRegion.name !== 'global') {
      mapDetailsRegion.dispersionIndex = this.calcService.getDispersionIndex(
        globalMatrix,
        matrix
      );
    }
  }


  updateMapDetails(mapMatrix) {
    const mapRegions = this.calcService.calculateRegions(mapMatrix);
    //update map details
    this.calculateRegionDetails(this.mapDetails.global, mapMatrix);

    //update map regions
    const diFront = this.calcService.getDispersionIndex(
      mapMatrix,
      mapRegions.front
    );

    this.calculateRegionDetails(
      this.mapDetails.front,
      mapRegions.front,
      mapMatrix
    );

    const diBack = this.calcService.getDispersionIndex(
      mapMatrix,
      mapRegions.back
    );
    this.calculateRegionDetails(
      this.mapDetails.back,
      mapRegions.back,
      mapMatrix
    );
    const diLeft = this.calcService.getDispersionIndex(
      mapMatrix,
      mapRegions.left
    );
    this.calculateRegionDetails(
      this.mapDetails.left,
      mapRegions.left,
      mapMatrix
    );
    const diRight = this.calcService.getDispersionIndex(
      mapMatrix,
      mapRegions.right
    );
    this.calculateRegionDetails(
      this.mapDetails.right,
      mapRegions.right,
      mapMatrix
    );
    const diQ1 = this.calcService.getDispersionIndex(mapMatrix, mapRegions.q1);
    this.calculateRegionDetails(this.mapDetails.q1, mapRegions.q1, mapMatrix);
    const diQ2 = this.calcService.getDispersionIndex(mapMatrix, mapRegions.q2);
    this.calculateRegionDetails(this.mapDetails.q2, mapRegions.q2, mapMatrix);
    const diQ3 = this.calcService.getDispersionIndex(mapMatrix, mapRegions.q3);
    this.calculateRegionDetails(this.mapDetails.q3, mapRegions.q3, mapMatrix);
    const diQ4 = this.calcService.getDispersionIndex(mapMatrix, mapRegions.q4);
    this.calculateRegionDetails(this.mapDetails.q4, mapRegions.q4, mapMatrix);
  }

}

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Subscription } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { SettingsService } from '../settings/settings.service';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  shiftSub: Subscription;
  userType: string;
  title: string;
  recordsShowSettingsDisabled: boolean;
  graphsShowSettingsDisabled: boolean;

  constructor(private settingService: SettingsService) { }

  reloadTabs() {
    // patient app navigation
    return this.settingService.fetchSettingFromServer().pipe(
      take(1),
      map((settings) => {
        if (Object.keys(settings).length > 0) {
          const settingList = Object.keys(settings).map(key =>  settings[key]);
          settingList.sort((a, b) => (new Date(a.date).getTime() > new Date(b.date).getTime()) ? 1 : -1);
          const mostRecentSetting = settingList[settingList.length - 1].data;
          SettingsService.settings = mostRecentSetting;
          this.settingService.setObject('settings', mostRecentSetting);
          return mostRecentSetting;
        }
        //this._settingData.next(SettingsService.settings);
      }),
      tap(settings => {
        if (settings && settings.showSettings) {
          this.recordsShowSettingsDisabled = !settings.showSettings.recordingsTab;
          this.graphsShowSettingsDisabled = !settings.showSettings.graphsTab;
        }
      })
    );
  }

    async getUserType() {
      return await Storage.get({key:'userType'}).then(
        type => type.value);
    }

    getDisabledTabs() {
      return {
        records: this.recordsShowSettingsDisabled,
        graphs: this.graphsShowSettingsDisabled
      };
    }


}

import { Injectable } from '@angular/core';
import { Filesystem, FilesystemDirectory, FilesystemEncoding, Capacitor } from '@capacitor/core';
import { DATA_DIRS } from './constants';
import { MapData } from './data-export.service';
import { ShiftData } from './map-tab/shift.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }


  async createDirectory(path: string) {
    try {
      await Filesystem.mkdir({
        path,
        directory: FilesystemDirectory.Data,
      });
    } catch (err) {
      console.log('Creating directory error', path, err);
    }
  }

  async createFile(dir: string, file: string) {
    const path = dir + '/' + file;
    try {
      const contents = await Filesystem.readFile({
        path,
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8,
      });
    } catch (err) {
      console.log('FILE ERROR', err.message);
      const androidFileNotFound = err.message.includes('File does not exist');
      const iosFileNotFound = err.message === err.message.includes('was not found');
      console.log( 'Error Android, ios',  androidFileNotFound, iosFileNotFound);
      if (androidFileNotFound || iosFileNotFound) {
        //create file
        try {
          console.log('platform is', Capacitor.getPlatform());
          if (Capacitor.getPlatform() === 'android' || 'web') {
            console.log('creating dir is', dir);
            this.createDirectory(dir);
          }
          const result = await Filesystem.writeFile({
            path,
            data: '',
            directory: FilesystemDirectory.Data,
            encoding: FilesystemEncoding.UTF8,
          });
        } catch (createErr) {
          console.log('Creating file error', createErr);
        }
      }
    }
  }

  async readFile(dir: string, file: string) {
    const path = dir + '/' + file;
    try {
      const contents = await Filesystem.readFile({
        path,
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8,
      });
      const parsedJSON = JSON.parse(
        '[' + contents.data.replace(/}{/g, '},{') + ']'
      );

      //turn the file of json objects {key: value}{key: value} into valid list of json objs [{key:value},{key:value}]
      // for (let index = 0; index < parsedJSON.length; index++) {
      //   const element = parsedJSON[index];
      // }
      return parsedJSON;
    }
    catch (err) {
      console.log('File does not exist: ', path);
      return [];
    }
  }

  async saveDailyShifts(file: string, data: ShiftData) {
    const path = DATA_DIRS.week_data_directory + '/' + file;
    try {
      this.createDirectory(DATA_DIRS.week_data_directory);
    } catch {
      console.log('Error creating dir');
    }
    try {
      this.createFile(DATA_DIRS.week_data_directory, file);
    } catch {
      console.log('Error creating file');
    }

    await Filesystem.appendFile({
      path,
      data: JSON.stringify(data),
      directory: FilesystemDirectory.Data,
      encoding: FilesystemEncoding.UTF8,
    });
  }

  async saveMapsLocally(file: string, maps: MapData[]) {
    const path = DATA_DIRS.mat_data_directory + '/' + file;
    this.createDirectory(DATA_DIRS.mat_data_directory);
    this.createFile(DATA_DIRS.mat_data_directory, file);
    for (const data of maps) {
      await Filesystem.appendFile({
        path,
        data: JSON.stringify(data).toString(),
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8,
      });
    }
  }

  async saveDataLocally(directory: string, file: string, data: any) {
    const path = directory + '/' + file;
    this.createDirectory(DATA_DIRS.mat_data_directory);
    this.createFile(DATA_DIRS.mat_data_directory, file);
      await Filesystem.appendFile({
        path,
        data: JSON.stringify(data).toString(),
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8,
      });
  }

  async saveTestLocally(file: string, data: ShiftData) {
    const path = DATA_DIRS.week_data_directory+ '/' + file;
    //this.createDirectory(DATA_DIRS.test_data_directory)
    this.createFile(DATA_DIRS.week_data_directory, file);
      await Filesystem.appendFile({
        path,
        data: JSON.stringify(data),
        directory: FilesystemDirectory.Data,
        encoding: FilesystemEncoding.UTF8,
      });
  }

  async getFilesFromDirectory(path: string) {
    const contents = await Filesystem.readdir({
      path,
      directory: FilesystemDirectory.Data,
    });

    console.log('SAVED FILES:', contents.files);
    return contents;
  }

  async getFileLength(dir: string, file: string) {
    const path = dir + '/' + file;
    const contents = await Filesystem.readFile({
      path,
      directory: FilesystemDirectory.Data,
      encoding: FilesystemEncoding.UTF8,
    });
    return contents.data.length;
  }

  async readFileLocalStorage(dir: string, file: string) {
    const path = dir + '/' + file;
    const contents = await Filesystem.readFile({
      path,
      directory: FilesystemDirectory.Data,
      encoding: FilesystemEncoding.UTF8,
    });
    const parsedJSON = JSON.parse(
      '[' + contents.data.replace(/}{/g, '},{') + ']'
    );
    return parsedJSON;
  }

  async deleteLocalStorage(dir: string, file: string) {
    const path = dir+ '/' + file;
    await Filesystem.deleteFile({
      path,
      directory: FilesystemDirectory.Data,
    });
  }
}

/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { Plugins } from '@capacitor/core';
import { map, tap } from 'rxjs/operators';
import { User } from './user.model';
import { BehaviorSubject, from, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SettingsService } from '../settings/settings.service';

const { Storage } = Plugins;

export interface AuthData {
  id: string;
  password: string;
  serverURL: string;
  role: string;

  token?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy{
  private _user = new BehaviorSubject<User>(null);
  private activeLogoutTimer: any;

  get userIsAuthenticated() {
    //return t or f is we have user token
    return this._user.asObservable().pipe(
      map((user) => {
        //return true; //DEBUG UNCOMMENT TO NOT NEED TO LOGIN
        if (user) {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }

  get userData() {
    return this._user.asObservable();
  }

  get clinicianIsAuthenticated() {
    //return t or f is we have user token
    return this._user.asObservable().pipe(
      map((user) => {
        //return true; //DEBUG UNCOMMENT TO NOT NEED TO LOGIN
        if (user && user.role === 'clinician') {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }


  get patientIsAuthenticated() {
    //return t or f is we have user token
    return this._user.asObservable().pipe(
      map((user) => {
        //return true; //DEBUG UNCOMMENT TO NOT NEED TO LOGIN
        if (user && user.role === 'patient') {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }

  get userId() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          return user.id;
        } else {
          return null;
        }
      })
    );
  }

  get token() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          return user.token;
        } else {
          return null;
        }
      })
    );
  }

  get role() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          return user.role;
        } else {
          return null;
        }
      })
    );
  }

  constructor(private http: HttpClient) {}

  ngOnDestroy(): void {
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
  }

  /**
   * Auto login in the user data to server if there is token saved
   *
   * @returns user data
   * @memberof AuthService
   */
  autoLogin() {
    return from(Storage.get({ key: 'authData' })).pipe(
      map((storedData) => {
        if (!storedData || !storedData.value) {
          return null;
        }
        const parseData = JSON.parse(storedData.value) as {
          userId: string;
          token: string;
          role: string;
        };

        const user = new User(
          parseData.userId,
          parseData.token,
          parseData.role,
        );
        return user;
      }),
      tap(user => {
        if (user){
          this._user.next(user);
        }
      }),
      map(user => !!user)
    );
  }

  // private autoLogout(duration: number) {
  //   if (this.activeLogoutTimer) {
  //     clearTimeout(this.activeLogoutTimer);
  //   }
  //   this.activeLogoutTimer = setTimeout(() => {
  //     this.logout()
  //   }, duration)
  // }

  /**
   * Login in the patient data to server
   *
   * @param patientId id of the patient
   * @param password user password
   * @param serverURL server url
   * @returns authenticated user data
   * @memberof AuthService
   *
   */
  loginPatient(patientId: string, password: string, serverURL: string) {
    return this.http
      .post<string>(
        serverURL + '/authenticatePatient',
        { patient: patientId, password}
      )
      .pipe(tap(resp => {
        this.setUserData(patientId, resp, 'patient');
        Storage.set({ key: 'userType', value: 'patient'});
      }));
  }

 /**
  * Login in the clinician data to server
  *
  * @param email id of the clinician
  * @param password user password
  * @param serverURL server url
  * @returns authenticated user data
  */
  loginClincician(email: string, password: string, serverURL: string) {
    return this.http
      .post<string>(
        serverURL + '/authenticateClinician',
        { email, password}
      )
      .pipe(tap(resp => {
        if (resp['admin'] === true) {
          this.setUserData(email, resp, 'admin');
          Storage.set({ key: 'userType', value: 'admin'});
        } else {
          this.setUserData(email, resp, 'clinician');
          Storage.set({ key: 'userType', value: 'clinician'});
        }
      }));
  }


  /**
   * Logout the user
   *
   * @memberof AuthService
   */
  logout() {
    this._user.next(null);
    Storage.clear();
    // Storage.remove({key: 'authData'});
    // Storage.remove({key: 'token'});
    SettingsService.settings.userId = null;
    if (this.activeLogoutTimer) {
      clearTimeout(this.activeLogoutTimer);
    }
    window.location.reload(); //this refresh the page to clear the data
  }

  /**
   * Set the user data
   *
   * @returns user data
   */
  getUserData() {
    return Storage.get({ key: 'authData' });
  }

  // signup(email: string, password: string) {
  //   return this.http
  //     .post<AuthResponseData>(
  //       `SET ME`,
  //       { email: email, password: password, returnSecureToken: true }
  //     )
  //     .pipe(tap(this.setUserData.bind(this)));
  // }

  /**
   * Set the user data
   *
   * @param id id of the user
   * @param responseToken token of the user
   * @param role role of the user
   */
  private setUserData(id: string, responseToken: string, role: string) {
    const user = new User(
      id,
      responseToken['token'],
      role
    );

    this._user.next(user);
    SettingsService.settings.userId = id;
    this.storeAuthData(
      id, responseToken['token'], role
    );
  }

  /**
   * Store the user data
   *
   * @param id id of the user
   * @param token token of the user
   * @param role role of the user
   * @memberof AuthService
   * @returns user data
   */
  private storeAuthData(
    id: string,
    token: string,
    role: string,
  ) {
    const data = JSON.stringify({
      id,
      token,
      role,
    });
    Storage.set({ key: 'authData', value: data });
    Storage.set({ key: 'token', value: token});
  }
}

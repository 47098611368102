export abstract class DateHelpers{

  static isOlderThan(date: Date, seconds: number) {
      const now = new Date().getTime();
      const diff = now - date.getTime();
      if (diff > (seconds * 1000)) {
        return true;
      } else {
        return false;
      }
  }

  static getDaysInWeek(startOfWeek: Date) {
    const daysOfWeek = [];
    for (let index = 0; index < 7; index++) {
      const day = new Date(
        startOfWeek.getFullYear(),
        startOfWeek.getMonth(),
        startOfWeek.getDate() + index
      );
        daysOfWeek.push(day);
    }
    return daysOfWeek;
  }

  static getFirstDayOfWeek(date: Date) {
    const day = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    day.setDate(day.getDate() - day.getDay());
    return day;
  }

  static getLastDayOfWeek(date: Date) {
    const day = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    day.setDate(day.getDate() + (6 - day.getDay()));
    return day;
  }

  static getAllWeekDays(currentDate: Date) {
    const firstDay = this.getFirstDayOfWeek(currentDate);
    const daysOfWeek = [];
    for (let index = 0; index < 7; index++) {
      const day = new Date(
        firstDay.getFullYear(),
        firstDay.getMonth(),
        firstDay.getDate() + index
      );
      daysOfWeek.push(day);
    }
    return daysOfWeek;
  }

  static isToday(date: Date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  static getAllDaysInYear(year) {
    const days = [];
    for (let month = 1; month <= 12; month++) {
      const daysInMonth = new Date(year, month, 0).getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        days.push(new Date(year, month - 1, day));
      }
    }
    return days;
  }

  static getAllDaysBetweenTwoDates(startDate: Date, endDate: Date) {
    const days = [];
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    while (start <= end) {
      days.push(new Date(start));
      start.setDate(start.getDate() + 1);
    }
    return days;
  }

  static getEachSecondBetweenTwoDates(startDate, endDate) {
    const dateList = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    while (start <= end) {
      dateList.push(new Date(start).toISOString());
      start.setSeconds(start.getSeconds() + 1);
    }
    return dateList;
  }

  static getEachMinuteBetweenTwoDates(startDate, endDate) {
    const dateList = [];
    const start = new Date(startDate);
    start.setMilliseconds(0);
    start.setSeconds(0);
    const end = new Date(endDate);
    while (start <= end) {
      dateList.push(new Date(start).toISOString());
      start.setMinutes(start.getMinutes() + 1);
    }
    return dateList;
  }

  static getAllHoursBetweenTwoDates(startDate: Date, endDate: Date) {
    const hours = [];
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    while (start < end) {
      hours.push(new Date(start));
      start.setHours(start.getHours() + 1);
    }
    return hours;
  }

  static intervals(startTime: Date, endTime: Date, timeStep: number) {
    const arr = [];
    while(startTime.getTime() < endTime.getTime()) {
      startTime  = new Date(startTime.getTime() + (timeStep * 1000));
      arr.push(startTime.getTime());
    }
    return arr;
  }

  static getDateDiff(startDate, endDate) {
    const diff = endDate.getTime() - startDate.getTime();
    const days = Math.floor(diff / (60 * 60 * 24 * 1000));
    const hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    const minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    const seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return { day: days, hour: hours, minute: minutes, second: seconds };
  }

  static isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import {  Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

export interface PopoverItem {
  title: string;
  message: string;
  image?: string;
}

@Component({
  selector: 'app-setting-info-pop',
  templateUrl: './setting-info-pop.component.html',
  styleUrls: ['./setting-info-pop.component.scss'],
})
export class SettingInfoPopComponent implements OnInit {
  popoverItem: PopoverItem;

  constructor(private popover: PopoverController) { }

  ngOnInit() {}

  ClosePopover() {
    this.popover.dismiss();
  }
}

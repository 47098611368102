import { PressureMatComponentModule } from '../../map-tab/pressure-mat/pressure-mat.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AlertGraphModalPageRoutingModule } from './alert-graph-modal-routing.module';

import { AlertGraphModalPage } from './alert-graph-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AlertGraphModalPageRoutingModule,
    PressureMatComponentModule,
  ],
  declarations: [AlertGraphModalPage],
  exports: [AlertGraphModalPage]
})
export class AlertGraphModalPageModule {}

/* eslint-disable no-underscore-dangle */
import { PressureMatService } from './pressure-mat/pressuremat.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { DataExportService, MapData } from '../data-export.service';
import { SettingsService } from '../settings/settings.service';
import { CalendarHelpers } from '../calendar-history/calendar-history-helpers';

@Injectable({
  providedIn: 'root',
})
export class RecordingService implements OnInit, OnDestroy {
  isRecording: boolean;
  currentMapList: MapData[];
  mapDataSub: Subscription;
  mapData: MapData;
  startRecordingTime: Date;
  private _isRecording = new BehaviorSubject<boolean>(null);

  constructor(
    private dataExportService: DataExportService,
    private pressureMatService: PressureMatService,
    private settingsService: SettingsService
  ) {
    this.currentMapList = [];
    this.isRecording = false;
  }


  get getIsRecording() {
    return this._isRecording.asObservable();
  }

  ngOnInit(): void {
    this.currentMapList = [];
    this.isRecording = false;
  }

  ngOnDestroy(): void {
    this.mapDataSub.unsubscribe();
  }

  async startShiftRecording(reason: string, previousMaps?: any[]) {
    this._isRecording.next(true);
    this.dataExportService.uploadWeightShift('start', reason).subscribe();
    if (!this.isRecording) {
      this.currentMapList = [];

      if(previousMaps) {
        this.currentMapList = previousMaps;
      }

    const currentTimestamp = new Date().toISOString();
    this.settingsService.set('previousShift', currentTimestamp);

      this.isRecording = true;
      this.startRecordingTime = new Date();
      this.mapDataSub = this.pressureMatService.aggregatedMapData.subscribe(
        (mapData) => {
          this.currentMapList.push(mapData);
        }
      );
    }
  }

  async stopShiftRecording(task: string[], reason: string) {
    if (this.isRecording) {
      this._isRecording.next(false);
      this.isRecording = false;
      this.mapDataSub.unsubscribe();
      try {
        await this.dataExportService.sendLongMapSequence(this.currentMapList, task);
        this.dataExportService.uploadWeightShift('stop', reason).subscribe( (resp) => {
          },
          (err) => {
            console.log('error uploading weight shift', err);
          }
        );
      } catch {
        console.log('Error uploading maps to server');
      }
      return this.currentMapList;
    }
  }

  async isFirstShiftOfDay(): Promise<boolean> {
    const isFirstShift = await this.settingsService.get('previousShift').then(
      (date) => {
        if (!CalendarHelpers.isToday(new Date(date.value)) || !date.value) {
          return true;
        }
        return false;
        });
    return isFirstShift;
  }
}

/* eslint-disable no-underscore-dangle */
import { HistoryDB, ShiftDB } from './clinician/patient.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, Subject, of } from 'rxjs';
import { take, map, catchError, switchMap } from 'rxjs/operators';
import { HistoryData } from './calendar-history/history-group-helper';
import { SettingsService } from './settings/settings.service';

export interface MapOutput {
  columns: number;
  date: string;
  patient?: string;
  rows: number;
  unit: string;
  values: number[];
}

interface DayMaps {
  date: string;
  maps: MapOutput[];
}

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  httpHeaders = {'Content-Type': 'application/json',
    responseType: 'text' as 'json'
  }; //text as json to workaround http failur during parse response

  serverMapList = [];

  public _historyData = new Subject<any[]>();

  get historyData() {
    return this._historyData.asObservable();
  }

  public _historyShiftData = new Subject<any[]>();

  get historyShiftData() {
    return this._historyShiftData.asObservable();
  }

  constructor(private httpClient: HttpClient) {
  }

  fetchMapsItems(maps: HistoryData[]): Observable<any> {
    const multiPuts = [];
    maps.forEach((singleMap) => {
      multiPuts.push(this.fetchMapItem(singleMap));
    });
    return forkJoin(multiPuts);
  }

  fetchMutlipleShifts(maps: string[]): Observable<any> {
    const multiPuts = [];
    maps.forEach((singleMap) => {
      multiPuts.push(this.fetchShiftMaps(singleMap));
    });
    return forkJoin(multiPuts);
  }

    fetchShiftMaps(mapId: string){
      return this.httpClient.get<MapOutput>(
        SettingsService.settings.serverURL + '/mapSequence', {params:{id: mapId} }
        ).pipe(
          take(1),
          map((res) => res),
          catchError(error => of(undefined))); // handle request error if any as null value in fork join
    }

  fetchMapItem(historyData: HistoryData): Observable<any> {
    return this.httpClient.get<MapOutput>(
      SettingsService.settings.serverURL + '/mapSequence', {params:{id: historyData.maps} }
      ).pipe(take(1));
  }

  fetchMapSequenceItem(mapSequenceId: string): Observable<any> {
    return this.httpClient.get<MapOutput>(
      SettingsService.settings.serverURL + '/mapSequence', {params:{id: mapSequenceId} }
      ).pipe(take(1));
  }


  getHistroyTimeConstraint(id: number, start: Date, end: Date) {
    let params = new HttpParams();
    if (id !== null) {
      params = params.append('patient', id.toString());
    }
    params = params.append('start', start.toString());
    params = params.append('end', end.toString());
    return this.httpClient.get<HistoryDB[]>(SettingsService.settings.serverURL + '/history', {params});
  }


  fetchHistory(patient?: string): Observable<any> {
    let params = {};
    if(patient) {
      params = {patient};
    }

    const httpOptions = {
      headers: this.httpHeaders,
      params
    };

    return this.httpClient.get(SettingsService.settings.serverURL + '/history', httpOptions).pipe(
      map(
      result => {
        const jsonResponse = JSON.parse(result.toString());
        return jsonResponse;
      }),
      map(result =>
         this.fetchMapsItems(result).pipe(take(1), map(
          mapList => mapList
          )).pipe(take(1), map(success => {
            this._historyData.next(success);
            return success;
        })).subscribe()
      )
      );
    }

    fetchShiftsByCause(cause: string): Observable<any> {
      let params = new HttpParams();
      params = params.append('cause', cause);
      return this.httpClient.get(SettingsService.settings.serverURL + '/weightShift', {params}).pipe(
        map(
          result => {
            const jsonResponse = JSON.parse(result.toString());
            return jsonResponse;
        }),
        map(result => {
          this._historyShiftData.next(result);
          for (const shift of result) {
            if (shift.maps) {
              return this.fetchMapsItems(shift).pipe(take(1), map(
                mapList => mapList
                )).pipe(take(1), map(success => success
              )).subscribe();
            }

          }
        })
        );
    }

    fetchShifts(): Observable<any> {
      return this.httpClient.get(SettingsService.settings.serverURL + '/weightShift', this.httpHeaders).pipe(
        map(
        result => {
          const jsonResponse = JSON.parse(result.toString());
          return jsonResponse;
        }),
        map(result => {
          this._historyShiftData.next(result);
          if (result.maps) {
            return this.fetchMapsItems(result).pipe(take(1), map(
              mapList => mapList
              )).pipe(take(1), map(success =>
                 success
            )).subscribe();
          }
        })
        );
      }

  fetchPatientShifts(patient?: string, start?: Date, end?: Date, cause?: string) {
    let params = {};
    if (patient && start && end) {
      params = {patient, start: start.toISOString(), end: end.toISOString()};
    } else if (patient && cause) {
      params = {patient, cause};
    }
    else if (start && end) {
      params = {start: start.toISOString(), end: end.toISOString()};
    }
    else if (patient) {
      params = {patient};
    }

    const httpOptions = {
      headers: this.httpHeaders,
      params
    };

    return this.httpClient.get<ShiftDB[]>(SettingsService.settings.serverURL + '/weightShift', httpOptions).pipe(
      map(result => {
       let shiftMaps = result.map(shift => shift.maps);
       shiftMaps = shiftMaps.filter(x => x !== undefined);
       this._historyShiftData.next(result);
       return shiftMaps;
      }),
      switchMap(shiftMaps => this.fetchMutlipleShifts(shiftMaps).pipe(
        take(1),
        map(success =>
          success
        ))
      ));
    }

  fetchNotes(mapSequenceId: string): Observable<any> {
    return this.httpClient.get(SettingsService.settings.serverURL + '/note',
    {params:{mapId: mapSequenceId} }).pipe(take(1),
      map(
      result => result),
      );
    }
}

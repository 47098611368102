import { Capacitor } from '@capacitor/core';
import { Injectable, OnInit } from '@angular/core';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { Entry, File } from '@ionic-native/file/ngx';

@Injectable({
  providedIn: 'root',
})
export class SoundsService implements OnInit {
  soundFile: MediaObject;

  constructor(private media: Media, private f: File) {
  }

  ngOnInit(): void {
    this.soundFile.onStatusUpdate.subscribe((status: number) => {
      if (status === 4) {//Media.MEDIA_STOPPED = 4;
        this.soundFile.release();
      }
    });
  }

  playSound(name: string) {
    if (Capacitor.platform === 'android' || Capacitor.platform === 'ios') {
      const path = this.f.applicationDirectory + 'public/assets/sounds/' + name;
      this.soundFile  = this.media.create(path.replace(/^file:\/\//, ''));
      this.soundFile.play();
    }
  }

  defaultSoundPath() {
    if (Capacitor.platform === 'android' || Capacitor.platform === 'ios') {
      const path = this.f.applicationDirectory + 'public/assets/sounds/' + 'chime.mp3';
      return path;
    }
  }

  defaultNotificationPath() {
    if (Capacitor.platform === 'android' || Capacitor.platform === 'ios') {
      const path = this.f.applicationDirectory + 'public/assets/sounds/' + 'inform.mp3';
      return path;
    }
  }

  getSounds() {
    if (Capacitor.platform === 'android' || Capacitor.platform === 'ios') {
      const path = this.f.applicationDirectory + 'public/assets/';
      return this.f.listDir(path, 'sounds');
    }
  }

  completePath(soundPath) {
    if (Capacitor.platform === 'android' || Capacitor.platform === 'ios') {
      const path = this.f.applicationDirectory + 'public/assets/sounds/' + soundPath;
      return path;
    }
  }
}

import { TabsService } from './../tabs/tabs.service';
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import { DateHelpers } from 'src/app/graphs/date-helper';
import { ModalController } from '@ionic/angular';
import { SettingsService } from 'src/app/settings/settings.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {  Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import {  Note, PatientDB } from './patient.type';
import { take, map, tap } from 'rxjs/operators';
import { HistoryGroupHelper } from '../calendar-history/history-group-helper';
import { AlertService } from '../graphs/alert.service';
import { MapOutput } from '../history.service';
import { HistoryShiftModalPage } from '../modals/history-shift-modal/history-shift-modal.page';
import { PressureMatDB } from './patient-list/pressure-map.type';

export interface HistoryDB {
  date: string;
  maps: string;
  patient: string;
  task: string[];
  end: string;
  __v: number;
  _id: string;
  data?: any[];
}

export interface AlertDB {
  data?: any;
  date: string;
  maps?: string;
  patient: string;
  __v: number;
  _id: string;
  type: string;
}

export interface ReminderDB {
  date: string;
  type: string;
  patient: string;
  responseDate: string;
  responseType: string;
  __v: number;
  _id: string;
}

export interface UserActionDB {
  data: any;
  date: string;
  patient: string;
  type: string;
  __v: number;
  _id: string;
}

export interface MetricDB {
  data: any;
  date: string;
  patient: string;
  type: string;
  __v: number;
  _id: string;
}

export interface ShiftDB {
  end?: string;
  endCause?: string;
  patient: string;
  start: string;
  startCause: string;
  __v: number;
  _id: string;
  maps?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  httpHeaders = {
    'Content-Type': 'application/json',
    responseType: 'text' as 'json',
  }; //text as json to workaround http failur during parse response
  patientList: PatientDB[] = [];
  alertList: any;
  public _patientMetrics = new BehaviorSubject<any[]>([]);
  public _hoverEvent = new BehaviorSubject<any[]>([]);
  private _patients = new BehaviorSubject<PatientDB[]>([]);
  private _patient = new BehaviorSubject<PatientDB>(null);



  constructor(
    private httpClient: HttpClient,
    private alertService: AlertService,
    private modalCtrl: ModalController,
    private tabsService: TabsService
    ) {}

    get patients() {
      return this._patients.asObservable();
    }

    get patient() {
    return this._patient.asObservable();
  }

  get patientMetrics() {
    return this._patientMetrics.asObservable();
  }

  get hoverEvent() {
    return this._hoverEvent.asObservable();
  }

  async getUserType() {
    return await this.tabsService.getUserType();
  }

  getPatientList() {
    return this.httpClient
      .get<PatientDB[]>(SettingsService.settings.serverURL + '/patients')
      .pipe(
        take(1),
        map((patientList) => {
          this._patients.next(patientList);
          this.patientList = patientList;
          this.populatePatientsData(patientList);
        })
      );
  }

  populatePatientsData(patientList: PatientDB[]) {
    const newPatientList = [];
    for (const p of patientList) {
      let daySinceCreated = DateHelpers.getAllDaysInYear(new Date().getFullYear());
      if (p.created) {
        daySinceCreated = DateHelpers.getAllDaysBetweenTwoDates(p.created,
          new Date());
      }
      this.fetchDailyShiftGoals(p.patientId, daySinceCreated).pipe(
        take(1),
        map(
          dailyShiftGoals => {
            p.dailyGoals = dailyShiftGoals;
          }
        )).subscribe(
          () => {
            this.fetchPatientInfo(p.patientId, daySinceCreated).pipe(
              take(1),
              map(
              dailyData => {
                p.dailyData = dailyData;
                p.data = dailyData.sort((a, b) => (new Date(a.date).getTime() > new Date(b.date).getTime()) ? 1 : -1);
                p.recordedTime = this.calculateRecordingTimeForPatient(p.data);
                p.lastTimeStamp = (p.data.length > 0) ? p.data[p.data.length - 1].date : null;
                p.recordingTimeString = this.convertMinsToHrsMins(p.recordedTime / 1000 / 60);

                const longShifts = p.data.filter(d => d.task.includes('long'));
                const midShifts = p.data.filter(d => d.task.includes('mid'));
                const shortShifts = p.data.filter(d => d.task.includes('short'));

                p.startTime = (p.data.length > 0) ? p.data[0].date: null;

                p.longShifts = HistoryGroupHelper.groupByDay(longShifts);
                p.midShifts = HistoryGroupHelper.groupByDay(midShifts);
                p.shortShifts = HistoryGroupHelper.groupByDay(shortShifts);
                p.dailyGoals.forEach(element => {
                  try {
                    const matchingDate = p.longShifts.find(d => d.date === element.date);
                    if (matchingDate) {
                      element.completedShifts = matchingDate.maps.length;
                      element.completePercentage = (matchingDate.maps.length / element.longShiftGoals) * 100;
                    } else {
                      element.completedShifts = 0;
                      element.completePercentage = 0;
                    }
                  } catch (error) {
                    console.error(error);
                  }
                });

                const shiftPercentSum = p.dailyGoals.reduce((a, {completePercentage}) => a + completePercentage, 0);
                p.shiftGoalComplete = (shiftPercentSum / p.dailyGoals.length) || 0;
                const shiftSum = p.dailyGoals.reduce((a, {completedShifts}) => a + completedShifts, 0);
                p.avgDaily = (shiftSum / p.dailyGoals.length) || 0;

                if (p.lastTimeStamp && p.startTime && p.settings) {
                  const allStudyDates = DateHelpers.getAllDaysBetweenTwoDates(p.startTime, p.data[p.data.length - 1].date);
                  const amountStudyDays = allStudyDates.length;
                  p.studyDays = allStudyDates;
                  const dayMetGoal = p.dailyGoals.filter(d => d.completedShifts > d.longShiftGoals);
                  p.dayGoalComplete = (dayMetGoal.length/amountStudyDays) * 100;
                }

                p.flagEvents = p.dailyData.filter(d => d.flag === true);

                // check if last time stamp is within a week
                const lastTimeStamp = new Date(p.lastTimeStamp);
                const now = new Date();
                const diff = now.getTime() - lastTimeStamp.getTime();
                const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                if (diffDays <= 1) {
                  p.active = true;
                } else {
                  p.active = false;
                }
              }
            )).subscribe(
              () => {
                newPatientList.push(p);
                newPatientList.sort((a, b) => (new Date(a.lastTimeStamp).getTime() > new Date(b.lastTimeStamp).getTime()) ? 1 : -1);
              }
              );
            }
            );
          }
  }

  getPatient(id: number) {
    return this.patients.pipe(
      take(1),
      map((patients) => {
        this._patient.next({ ...patients.find((p) => p.patientId === id) });

        return { ...patients.find((p) => p.patientId === id) };
      })
    );
  }

  deletePatient(id: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        patient: id,
      },
    };
    return this.httpClient.delete(
      SettingsService.settings.serverURL + '/patients',
      options
    );
  }

  getHistroy(id: number) {
    const historyList = [];
    let params = new HttpParams();
    params = params.append('patient', id.toString());
    return this.httpClient.get<HistoryDB[]>(
      SettingsService.settings.serverURL + '/history',
      { params }
    );
  }

  getHistroyTimeConstraint(
    id: number,
    firstDayOfWeek: Date,
    lastDayOfWeek: Date
  ) {
    const historyList = [];
    let params = new HttpParams();
    params = params.append('patient', id.toString());
    params = params.append('start', firstDayOfWeek.toString());
    params = params.append('end', lastDayOfWeek.toString());
    return this.httpClient.get<HistoryDB[]>(
      SettingsService.settings.serverURL + '/history',
      { params }
    );
  }

  getSingleDateHistory(
    id: string,
    start: Date,
    end: Date
  ) {
    const historyList = [];
    let params = new HttpParams();
    params = params.append('patient', id);
    params = params.append('start', start.toString());
    params = params.append('end', end.toString());

    return this.httpClient.get<HistoryDB[]>(
      SettingsService.settings.serverURL + '/history',
      { params }
    );
  }

  fetchMutlipleHistory(history: HistoryDB[]): Observable<any> {
    return forkJoin<HistoryDB>(history).pipe(
      map((response) => {
        const historyByDay = HistoryGroupHelper.groupByDayGetTime(response);
        return historyByDay;
      })
    );
  }

  getDetail(endpoint: string, id: number) {
    let params = new HttpParams();
    params = params.append('patient', id.toString());
    return this.httpClient.get(
      SettingsService.settings.serverURL + `/${endpoint}`,
      { params }
    );
  }

  async getMapSequence(mapSequenceId: string): Promise<any> {
    let params = new HttpParams();
    params = params.append('id', mapSequenceId.toString());
    const response = await this.httpClient
      .get<PressureMatDB[]>(
        SettingsService.settings.serverURL + `/mapsequence`,
        { params }
      )
      .pipe(take(1))
      .toPromise();
    return response;
  }

  getMapSequences(mapSequenceId: string[]): Observable<any> {
    const multiPuts = [];
    //due to url limit if the sequence is more that 50 need to split requests
    const n = 50;
    const sequenceLists = mapSequenceId.reduce(
      (r, i) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        r[r.length - 1].length === n ? r.push([i]) : r[r.length - 1].push(i);
        return r;
      },
      [[]]
    );

    sequenceLists.map((i) => {
      let params = new HttpParams();
      params = params.append('id', i.toString());
      multiPuts.push(
        this.httpClient.get<PressureMatDB[]>(
          SettingsService.settings.serverURL + `/mapsequences`,
          { params }
        )
      );
    });
    return forkJoin(multiPuts);
  }

  fetchMapsItems(maps: string[]): Observable<any> {
    const multiPuts = [];
    maps.forEach((singleMap) => {
      multiPuts.push(this.fetchMapItem(singleMap));
    });
    return forkJoin(multiPuts);
  }

  fetchMapItem(mapSequenceId: string): Observable<any> {
    return this.httpClient
      .get<PressureMatDB[]>(
        SettingsService.settings.serverURL + '/mapSequence',
        { params: { id: mapSequenceId } }
      )
      .pipe(take(1));
  }

  calculateRecordingTime(dailyData) {
    if (dailyData.length > 1) { // more than one day
      for (const day of dailyData) {
        const sortedDay = day.data.sort((a, b) =>
          new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1
        );
        const startTime = sortedDay[0].date;
        const endTime = sortedDay[sortedDay.length - 1].end;
        const recordingTime =
          new Date(endTime).getTime() - new Date(startTime).getTime();
        day.recordingTime = recordingTime;
        day.recordingTimeString = this.convertMinsToHrsMins(
          recordingTime / 1000 / 60
        );
        day.startTime = startTime;
        day.endTime = endTime;
      }
    } else {  // one day
      try{
        const sortedDay = dailyData[0].data.sort((a, b) =>
            new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1
          );
          const startTime = sortedDay[0].date;
          const endTime = sortedDay[sortedDay.length - 1].end;
          const recordingTime =
            new Date(endTime).getTime() - new Date(startTime).getTime();
            dailyData[0].recordingTime = recordingTime;
            dailyData[0].recordingTimeString = this.convertMinsToHrsMins(
            recordingTime / 1000 / 60
          );
          dailyData[0].startTime = startTime;
          dailyData[0].endTime = endTime;
        } catch (error) {
          console.log(error);
        }
      }
  }

  calculateRecordingTimeForPatient(data) {
    let ms = 0;
    for (const recording of data) {
      ms +=
        new Date(recording.end).getTime() - new Date(recording.date).getTime();
    }
    return ms;
  }

  calculateOutOfChairTime(dailyData) {
    for (const day of dailyData) {
      const actualRecordedMinutes = new Set();
      let totalPossibleRecordedMinutes = [];
      if (day.data && day.data.length > 0) {
        const startTime = day.data[0].date;
        const endTime = day.data[day.data.length - 1].end;
        totalPossibleRecordedMinutes = DateHelpers.getEachMinuteBetweenTwoDates(startTime, endTime);
        for (const sample of day.data) {
          const sampleStartTime = sample.date;
          const sampleEndTime = sample.end;
          const recordedMinutes = DateHelpers.getEachMinuteBetweenTwoDates(sampleStartTime, sampleEndTime);
          for (const recordedMinute of recordedMinutes) {
            actualRecordedMinutes.add(recordedMinute);
          }
        }
      }
      const actualRecordedArray = [...actualRecordedMinutes];
      const difference = totalPossibleRecordedMinutes.filter(x => !actualRecordedArray.includes(x));
      day.noDataMinutes = this.groupContinoiusMinutes(difference); // the minutes without any data
      const minuteCount = day.noDataMinutes.flat().length;
      day.oocTime = minuteCount * 1000 * 60;
      day.oocTimeString = this.convertMinsToHrsMins(minuteCount);
      }
  }

  computeZeroMatPressure(day) {
    let outOfChairSampleCount = 0;
    let noDataMinutes = [];

    const dayMapData = day[0].maps
      .map((mapData) =>
        mapData.map((sample) => ({
          date: sample.date,
          values: sample.values,
          rows: sample.rows,
        }))
      )
      .flat();

    //check each minute for data
    for (
      let index = new Date(dayMapData[0].date).getTime() / (1000 * 60);
      index <
      new Date(dayMapData[dayMapData.length - 1].date).getTime() / (1000 * 60);
      index++
    ) {
      const minute = new Date(index * (1000 * 60));
      minute.setSeconds(0);
      const nextMinute = new Date(minute.getTime() + 60000);
      dayMapData.map(
        (x) => (x.average = x.values.reduce((a, b) => a + b) / x.values.length)
      );
      if (
        dayMapData.filter(
          (e) =>
            new Date(e.date) >= minute &&
            new Date(e.date) <= nextMinute &&
            e.average > 10
        ).length > 0
      ) {
        //e.values.some(item => item !== 0)).length > 0)
      } else {
        noDataMinutes.push(minute);
        outOfChairSampleCount++;
      }
    }
    noDataMinutes = this.groupContinoiusMinutes(noDataMinutes);
    return { outOfChairMinutes: outOfChairSampleCount, noDataMinutes };
  }

  groupContinoiusMinutes(minutesTimeStamp): any[] {
    const result = [];
    let temp = [];
    let difference;

    const array = minutesTimeStamp.map((x) => new Date(x).getMinutes());

    for (let i = 0; i < array.length; i += 1) {
      if (difference !== array[i] - i) {
        if (difference !== undefined) {
          result.push(temp);
          temp = [];
        }
        difference = array[i] - i;
      }
      temp.push(minutesTimeStamp[i]);
    }

    if (temp.length) {
      result.push(temp);
    }

    return result;
  }

  fetchOutOfChairData(patientId: string, dayData) {
    if (dayData.length > 1) { // more thank one day
      for (const day of dayData) {
        const start = new Date(day.date).setHours(0, 0, 0, 0);
        const end = new Date(day.date).setHours(23, 59, 59, 999);

        this.alertService
          .fetchOutOfChairWithConstraints(
            patientId,
            new Date(start),
            new Date(end)
          )
          .pipe(
            take(1),
            tap((success) => {
              //this.alertList = success;
              day.outOfChair = success;
              let oocTime = 0;
              for (const event of day.outOfChair) {
                const oocMS =
                  new Date(event.endTime).getTime() -
                  new Date(event.startTime).getTime();
                oocTime += oocMS;
              }
              day.oocTime = oocTime;
              day.oocTimeString = this.convertMinsToHrsMins(
                day.oocTime / 1000 / 60
              );
            })
          )
          .subscribe();
      }
    } else { // only one day
      try{
        const start = new Date(dayData[0].date).setHours(0, 0, 0, 0);
          const end = new Date(dayData[0].date).setHours(23, 59, 59, 999);

          this.alertService
            .fetchOutOfChairWithConstraints(
              patientId,
              new Date(start),
              new Date(end)
            )
            .pipe(
              take(1),
              tap((success) => {
                //this.alertList = success;
                dayData[0].outOfChair = success;
                let oocTime = 0;
                for (const event of dayData[0].outOfChair) {
                  const oocMS =
                    new Date(event.endTime).getTime() -
                    new Date(event.startTime).getTime();
                  oocTime += oocMS;
                }
                dayData[0].oocTime = oocTime;
                dayData[0].oocTimeString = this.convertMinsToHrsMins(
                  dayData[0].oocTime / 1000 / 60
                );
              })
            )
            .subscribe();
      }
      catch(e){
        console.log(e);
      }
    }
  }

  fetchReminders(patientId: string, dayData) {
    if (dayData.length > 1) { // more than one day
      for (const day of dayData) {
        const start = new Date(day.date).setHours(0, 0, 0, 0);
        const end = new Date(day.date).setHours(23, 59, 59, 999);
        this.alertService
          .fetchRemindersWithConstraints(
            patientId,
            new Date(start),
            new Date(end)
          )
          .pipe(
            take(1),
            map((success) => {
              //this.alertList = success;
              day.reminders = success;
            })
          )
          .subscribe();
      }
    } else { // only one day
      try{

        const start = new Date(dayData[0].date).setHours(0, 0, 0, 0);
          const end = new Date(dayData[0].date).setHours(23, 59, 59, 999);
          this.alertService
            .fetchRemindersWithConstraints(
              patientId,
              new Date(start),
              new Date(end)
            )
            .pipe(
              take(1),
              map((success) => {
                //this.alertList = success;
                dayData[0].reminders = success;
              })
            )
            .subscribe();
      }
      catch(e){
        console.log(e);
      }
    }
  }

  fetchAlerts(patientId: string, dayData) {
    if (dayData.length > 1) { // more than one day
      for (const day of dayData) {
        const start = new Date(day.date).setHours(0, 0, 0, 0);
        const end = new Date(day.date).setHours(23, 59, 59, 999);
        this.alertService
          .fetchAlertsWithConstraints(patientId, new Date(start), new Date(end))
          .pipe(
            take(1),
            map((success) => {
              //this.alertList = success;
              day.alerts = success;
            })
          )
          .subscribe();
      }
    } else { // only one day
      try{
        const start = new Date(dayData[0].date).setHours(0, 0, 0, 0);
          const end = new Date(dayData[0].date).setHours(23, 59, 59, 999);
          this.alertService
            .fetchAlertsWithConstraints(patientId, new Date(start), new Date(end))
            .pipe(
              take(1),
              map((success) => {
                //this.alertList = success;
                dayData[0].alerts = success;
              })
            )
            .subscribe();
      }
      catch(e){
        console.log('error', e);
      }
    }
  }

  fetchPatientDayData(patient, days) {
    const start = days[0];
    const end = days[days.length - 1];

    return this.getHistroyTimeConstraint(patient, start, end).pipe(
      map((data) => {
        const dayGroupedData = HistoryGroupHelper.groupDataByDate(data);
        return dayGroupedData;
      })
    );
  }

  fetchPatientSettings(patient?, start?, end?) {
    let params = {};
    if (patient && start && end) {
      params = { patient, start: start.toISOString(), end: end.toISOString() };
    } else if (patient) {
      params = { patient };
    }

    const httpOptions = {
      headers: this.httpHeaders,
      params,
    };

    return this.httpClient
      .get<any[]>(SettingsService.settings.serverURL + '/settings', httpOptions)
      .pipe(take(1));
  }

  fetchAllDaySettings(patientId: string, dayData) {
    const promises = [];
    if (dayData.length > 1) { // more than one day
      for (const day of dayData) {
        promises.push(this.fetechSingleDaySettings(patientId, day));
      }
    } else { // only one day
      promises.push(this.fetechSingleDaySettings(patientId, dayData[0]));
    }
    return Promise.all(promises);
  }

  fetechSingleDaySettings(patientId: string, day: any) {
    try{

      const start = new Date(day.date).setHours(0, 0, 0, 0);
      const end = new Date(day.date).setHours(23, 59, 59, 999);
      return this.fetchPatientSettings(patientId, new Date(start), new Date(end))
        .pipe(
          take(1),
          map((success) => {
            if (success.length > 0) {
              day.settings = success[0];
              day.firstDayGoal = success[0].data.shiftDailyGoalCount;
              day.lastDayGoal =
                success[success.length - 1].data.shiftDailyGoalCount;
              day.shiftsPerHour = success[0].data.shiftsPerHour;
            } else {
              //set default settings if not values...
              day.settings = SettingsService.settings;
              day.firstDayGoal = SettingsService.settings.shiftDailyGoalCount;
              day.lastDayGoal = SettingsService.settings.shiftDailyGoalCount;
              day.shiftsPerHour = SettingsService.settings.shiftsPerHour;
            }
          })
        )
        .toPromise();
    }
    catch(e){
      console.log(e);
    }
  }

  convertMinsToHrsMins(mins) {
    const h = Math.floor(mins / 60);
    const m = Math.floor(mins % 60);
    const hString = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    const mString = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${hString}:${mString}`;
  }

  async openHistoryModal(mapData: MapOutput[], historyInfo?: any) {
    const modal = await this.modalCtrl.create({
      component: HistoryShiftModalPage,
      componentProps: {
        paramMethod: 'patient_day',
        paramMapData: mapData,
        paramStartTime: new Date(mapData[0].date),
        paramTitle: `Shift: ${mapData[0].date}`,
        paramHistoryInfo: historyInfo,
      },
    });

    modal.onDidDismiss();
    return await modal.present();
  }

  newPatient() {
    return this.httpClient
      .put(SettingsService.settings.serverURL + '/patients', this.httpHeaders);
  }

  newPatientWithId(patientId: string, password: string) {
    return this.httpClient
      .put(SettingsService.settings.serverURL + '/patients', { patient: patientId, password: password}, this.httpHeaders).pipe(
        take(1),
      );
  }

  addExistingPatinet(patientId: string) {
    return this.httpClient
      .post(
        SettingsService.settings.serverURL + '/patients/', { patient: patientId},
        this.httpHeaders
      ).pipe(
        take(1)
      );
  }

  resetPassword(newPassword: string, patient: string) {
    return this.httpClient.post(
      SettingsService.settings.serverURL + '/patientpass/',
      {newPassword, patient},
      this.httpHeaders
    );
  }

  fetchDailyShiftGoals(patient, days) {
    const start = days[0];
    const end = days[days.length - 1];
    const dailyGoal = [];

    //get the daily goals
    return this.fetchPatientSettings(patient, start, end).pipe(
      take(1),
      map(
        success => {
          //const settingList = Object.entries(success);
          const daySettingList = HistoryGroupHelper.groupDataByDate(success);
          for (const d of daySettingList) {
            const day = d.date;
            const finalDaySetting = d.data[0]; //gets the first settings for the day
            dailyGoal.push({
              date: day,
              longShiftGoals: finalDaySetting.data.shiftDailyGoalCount,
            });
          }
          return dailyGoal;
        }
    ));
  }

  fetchPatientInfo(patient, days){
    const start = days[0];
    const end = days[days.length - 1];
    const dailyGoal = [];
    //adjust end to be the end of the day
    const adjustedEnd = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 999);

    return this.getHistroyTimeConstraint(patient, start, adjustedEnd).pipe(
      map(
        data => data
      )
    );
  }

  addPatientNotes(patientId, content) {
    return this.httpClient
      .post(
        SettingsService.settings.serverURL + '/cliniciannotes',
        { patient: patientId, content,  timestamp: new Date().toISOString() },
        this.httpHeaders
      ).pipe(
        take(1)
      );
  }

  fetchPatientNotes(patientId): Observable<Note[]> {
    let params = {};
    params = { patientId};

    const httpOptions = {
      headers: this.httpHeaders,
      params,
    };

    return this.httpClient
      .get<Note[]>(
        SettingsService.settings.serverURL + '/cliniciannotes/',
        httpOptions,
      ).pipe(
        take(1),
        map((data: any[]) => data.map((note) => ({
              id: note._id,
              content: note.content,
              timestamp: new Date(note.timestamp),
            })
      )));
  }

  deletePatientNotes(noteId) {
    return this.httpClient
      .delete(
        SettingsService.settings.serverURL + '/cliniciannotes/', { params: { noteId }, headers: this.httpHeaders},
      ).pipe(
        take(1)
      );
  }
}

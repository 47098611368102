export const BLANK_MAP = {
  origin: 'http://t7.local',
  blankMap: {
    unit: 'mmHg',
    rows: 16,
    values: new Array(256).join('0').split(''),
    columns: 16,
  },
  cellMax: 200,
  cellsPerRegion: 4,
  rows: 16,
  regionRows: 2,
  regions: 64,
  values: new Array(256),
  unit: 'mmHg',
  columns: 16,
  regionCols: 2,
};

export const DATA_DIRS = {
  mat_data_directory: 'mat_data',
  shift_data_directory: 'shift_data',
  shift_maps_directory: 'shift_maps',
  alert_data_directory: 'alert_data',
  reminder_data_directory: 'reminder_data',
  week_data_directory:  'weekly_shifts',
  test_data_directory:  'test',
};

export const SERVERS = {
  mayo: 'https://api.awshift.ahc.umn.edu',
  test: 'https://awshift-test-server.azurewebsites.net/',
  va: 'https://awshift-va.azurewebsites.us/',
  local: 'http://localhost:8080'
};

export const NOTIFICATION_IDS = {
  dailyNotification: 8,
};

/* eslint-disable no-underscore-dangle */
import { MapOutput } from './history.service';
import { PressureMatService } from './map-tab/pressure-mat/pressuremat.service';
import { DataExportService, MapData } from './data-export.service';
import { ShiftService } from 'src/app/map-tab/shift.service';
import { SettingsService } from 'src/app/settings/settings.service';
import { LocalNotificationService } from './local-notification.service';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatrixHelpers } from './matrix-helpers';
import { ToastController } from '@ionic/angular';

export class MapRegions {
  front: number[][];
  back: number[][];
  left: number[][];
  right: number[][];

  q1: number[][];
  q2: number[][];
  q3: number[][];
  q4: number[][];
}

export class AlertRegions {
  id: string;
  x: number;
  y: number;
  pressure: number;
  count?: number;
}

export class RegionDetails {
  name: string;
  currentPPI: number;
  averagePPI: number;
  rollingPPIArray: number[];
  ppiChange: number;
  dispersionIndex?: number; //no DI for global
  ppiData?: any[];
}

export class MapDetails {
  global: RegionDetails;
  front: RegionDetails;
  back: RegionDetails;
  left: RegionDetails;
  right: RegionDetails;

  q1: RegionDetails;
  q2: RegionDetails;
  q3: RegionDetails;
  q4: RegionDetails;
}

@Injectable({
  providedIn: 'root',
})
export class CalculateMetricsService {
  runningAverage: number[] = [];
  isPressureAlert = false;
  runningRegionAverage: any;
  average30MinutePressureArray: number[] = [];
  shiftSub: Subscription;
  isShifting = false;
  lastShiftData: any;
  alertRegions = [];
  localAlertWaitTime = null;
  localAlertCanStartTime: Date;
  isBackgroundAlert = false;

  public _mapDetails = new Subject<MapDetails>();
  pressureWaitTimerStart = null;
  pressureCanAlertTime: Date;
  pressureAlertDelay = 300; // wait 5 minutes - 300 seconds so to not spam alerts;

  get mapDetailsObs() {
    return this._mapDetails.asObservable();
  }

  constructor(
    public alertController: AlertController,
    private localNotificationService: LocalNotificationService,
    private shiftService: ShiftService,
    private dataExportService: DataExportService,
    private pressureMatService: PressureMatService,
    private toastController: ToastController
  ) {
  }


calculateLocalRegions(mapMatrix: number[][]) {

  if (!this.localAlertWaitTime) {
    this.localAlertWaitTime = new Date();
    this.localAlertCanStartTime = new Date(
      this.localAlertWaitTime.getTime() + this.pressureAlertDelay * 1000);
    }


  const correctedWindowedRegions = MatrixHelpers.slidingWindow(mapMatrix, 2);
  for (let regionRowIndex = 0; regionRowIndex < correctedWindowedRegions.length; regionRowIndex++) {
    const regionRow = correctedWindowedRegions[regionRowIndex];
    for (let regionColumnIndex = 0; regionColumnIndex < regionRow.length; regionColumnIndex++) {
      const regionPoint = regionRow[regionColumnIndex];
      const regionPPI = MatrixHelpers.averagePressureMatrix(regionPoint);
      if (regionPPI > SettingsService.settings.localAlertTriggerPpi)
      {
        this.pushToAlertRegions(this.alertRegions, {id:'alertCanvas_live', x:regionColumnIndex, y:regionRowIndex, pressure: regionPPI});
      }
      else {
        this.clearAlertRegions(this.alertRegions, {id:'alertCanvas_live', x:regionColumnIndex, y:regionRowIndex, pressure: regionPPI});
        //activity in region to remove the alert flag
        // this.pressureMatService.clearSingleCells('live', regionRowIndex, regionColumnIndex, 2);
      }
    }
  }
  const alertArrays = this.alertRegions.filter(x => x.count > (SettingsService.settings.localAlertTimeThreshold) * 60);

  if (alertArrays.length > 0 && !this.isPressureAlert
      && new Date() > this.localAlertCanStartTime // check that it is after can alert time
    ) {

    for (const region of alertArrays) {
      this.pressureMatService.drawSingleCell('alertCanvas_live', region.x, region.y, 'pink', 2, 2, 4);
    }
    const notifiactionTime = new Date(new Date().getTime() + 1000);

    this.localNotificationService.sendLocalNotification(
      `Local Pressure Alert at ${new Date().toLocaleTimeString()}`,
      `Local area of pressure was over
      ${SettingsService.settings.localAlertTriggerPpi} mmHg for ${SettingsService.settings.localAlertTimeThreshold} mins`,
      notifiactionTime,
      2,
      false
    );
    if (!this.isPressureAlert) {
      this.isBackgroundAlert = false;
      this.isPressureAlert = true;
      this.showLocalPressureAlert(
        SettingsService.settings.localAlertTriggerPpi,
        SettingsService.settings.localAlertTimeThreshold);
      const currentMap = {
        date: new Date().toISOString(),
        rows: mapMatrix.length,
        columns: mapMatrix[0].length,
        unit: 'mmHg',
        values: mapMatrix.reduce((accumulator,value) => accumulator.concat(value), [])
      };

      this.dataExportService
        .sendMapToServer(currentMap)
        .pipe(take(1))
        .toPromise()
        .then(
          (mapId) => {
            this.dataExportService._uploadMapId.next(mapId);
            //upload the current map and send alert data to server with the mapId response of upload
            this.dataExportService.sendAlert(
              new Date(),
              'localPressure',
              {
                regionData: alertArrays,
                triggerPct: SettingsService.settings.localAlertTriggerPpi,
                triggerTime: SettingsService.settings.localAlertTimeThreshold,
                lastShift: this.lastShiftData,
              },
              mapId
            );
          },
          (error) => {
            console.log('Sent Map error in calculate-metrics.service', error);
          }
        );
      }
    }
    else if (alertArrays.length > 0 && this.isBackgroundAlert) {
      this.pressureMatService.clearSingleCells('alertCanvas_live', 0, 0, 16);
      setTimeout(() => {
      for (const region of alertArrays) {
        this.pressureMatService.drawSingleCell('alertCanvas_live', region.x, region.y, 'pink', 2, 2, 4);
      }}, 250);
      this.presentToast();
    }
    else if (alertArrays.length === 0) {
      this.isBackgroundAlert = false;
    }
}

clearAlertRegions(arr: AlertRegions[], obj: AlertRegions) {
  const index = arr.findIndex((e) => e.x === obj.x && e.y === obj.y);
  if (index !== -1) {
    this.pressureMatService.clearSingleCells('alertCanvas_live', obj.x, obj.y, 2);
    this.pressureMatService.updateSingleCellCanvas('alertCanvas_live');
    arr.splice(index, 1);
  }
}

pushToAlertRegions(arr: AlertRegions[], obj: AlertRegions) {
  // this finds if the alert region is already present, if it is adds to count if not creates new count.
  const index = arr.findIndex((e) => e.x === obj.x && e.y === obj.y);
  if (index === -1) {
    const newObj = obj;
    newObj.count = 1;
    arr.push(newObj);
  } else {
    const updateObject = (arr[index]);
    updateObject.count = updateObject.count +1;
    updateObject.pressure = obj.pressure;
    arr[index] = updateObject;
  }
}

  calculateRegions(mapMatrix: number[][]) {
    const mapRegions: MapRegions = new MapRegions();
    mapRegions.front = [];
    mapRegions.back = [];
    mapRegions.left = [];
    mapRegions.right = [];
    mapRegions.q1 = [];
    mapRegions.q2 = [];
    mapRegions.q3 = [];
    mapRegions.q4 = [];


    for (let i = 0; i < mapMatrix.length; i++) {
      const halfwayThrough = Math.floor(mapMatrix[i].length / 2);
      mapRegions.left.push(mapMatrix[i].slice(0, halfwayThrough)); // this is full left side
      mapRegions.right.push(mapMatrix[i].slice(halfwayThrough, mapMatrix[i].length)); // this is full right side

      if (i < halfwayThrough) {
        //front half
        mapRegions.front.push(mapMatrix[i].slice(0, mapMatrix[i].length)); // this is front

        mapRegions.q1.push(
          mapMatrix[i].slice(halfwayThrough, mapMatrix[i].length)
        ); // this is full right side
        mapRegions.q2.push(mapMatrix[i].slice(0, halfwayThrough)); // this is full left side
      }
      if (i >= halfwayThrough) {
        //back half
        mapRegions.back.push(mapMatrix[i].slice(0, mapMatrix[i].length)); // this is back

        mapRegions.q4.push(
          mapMatrix[i].slice(halfwayThrough, mapMatrix[i].length)
        ); // this is full right side
        mapRegions.q3.push(mapMatrix[i].slice(0, halfwayThrough)); // this is full left side
      }
    }
    return mapRegions;
  }

  calculate30MinuteAverage(pressureArray: number[]) {
    const average30MinutePressure =
      pressureArray.reduce((p, c) => p + c, 0) / pressureArray.length;

    return Math.round(average30MinutePressure);
  }

  /**
   * getPeakPressureIndexMatrix - Gets the peak pressure index from a 2d Matrix
   * @param matrix - the full matrix with all of the values contained in the map
   * @returns {number} - the peak pressure for the region given
   */

  getPeakPressureIndexMatrix(matrix) {
    const slidingWindowArray = MatrixHelpers.slidingWindow(matrix, 2);
    const slidingPressureMetricsRow = slidingWindowArray.map((currRow, row) => {
      const slidingPressureMetrics = currRow.map((currElement, column) => {
        const ppi =  currElement.flat().reduce((a, b) => a + b) / currElement.flat().length;
        return {x: column, y: row, ppi, data: currElement};
      });
      return slidingPressureMetrics;
    });

    //filter ppi if it is not null
    const filterArray = slidingPressureMetricsRow.map((ppiData) => ppiData.filter((e) => e.ppi));
    //flatten array of objects
    const flattenedPressureMetrics = filterArray.flat();

    //get the map in the pressure of sliding windows
    const ppiOutput = flattenedPressureMetrics.reduce((prev, current) => (prev.ppi > current.ppi) ? prev : current, 0);
    return ppiOutput;
  }

  aggregateMapsIntoSingleMap(maps: MapOutput[]) {

    const result = [];
    let filterMaps = maps.filter(MatrixHelpers.filterNonzero);
    if (filterMaps.length === 0) {
      filterMaps = maps;
    }

    // get average of arrays of data into single array for aggregate map
    for (let i = 0; i < filterMaps[0].values.length; i++) {
      let num = 0;
      //still assuming all arrays have the same amount of numbers
      for (const dataPoint of filterMaps) {
        num += dataPoint.values[i];
      }
        result.push(Math.round(num / filterMaps.length));
    }
        const newMap = {
          date: new Date().toISOString(),
          rows: 16,
          columns: 16,
          unit: 'mmHg',
          values: result,
        };
    return newMap;
  }
  /**
   * getDispersionIndex - getting the pressure of the area relative to the pressure of the entire mat
   * Formula -------> DI= A/(A+B)
   * @param matrix - expecting array for all map values
   * @param region - expecting matrix for region
   * @returns {number} - Dispersion index
   */
  getDispersionIndex(matrix, region) {
    let totalSum = 0;
    let regionSum = 0;
    let i;
    let j;

    for (i = 0; i < region.length; i += 1) {
      for (j = 0; j < region[i].length; j += 1) {
        regionSum += region[i][j];
      }
    }

    for (i = 0; i < matrix.length; i += 1) {
      for (j = 0; j < matrix[i].length; j += 1) {
        totalSum += matrix[i][j];
      }
    }
    if (totalSum !== 0) {
      return Math.round((regionSum / totalSum) * 100);
    }
    return 0;
  }

  checkRiseInPressure(
    regionName: string,
    pressureIndex: number,
    pressureArray: number[],
    currentMap: MapData,
    patient: boolean,
  ) {
    /*
     * Global rise in pressure alert
     * This alert triggers when the newest map's total pressure jumps from the rolling average by some threshold
     */

    if (!this.pressureWaitTimerStart) {
      this.pressureWaitTimerStart = new Date();
      this.pressureCanAlertTime = new Date(
        this.pressureWaitTimerStart.getTime() + this.pressureAlertDelay * 1000
      );
    }


    // 1.5 meaning 50% rise 1 = no change 0.50 means a drop by 50%
    const threshold = 1 + (Math.floor(100 / SettingsService.settings.globalAlertTriggerThreshold));

    const rollingAverage =
      pressureArray.reduce((p, c) => p + c, 0) / pressureArray.length;

    //start running list for global pressure
    const percentChange = pressureIndex / rollingAverage;
    if (patient) {
      if (
        percentChange > threshold &&
        !this.isPressureAlert &&
        new Date() > this.pressureCanAlertTime // check that it is after can alert time
      ) {
        this.isPressureAlert = true;

        this.shiftService.lastShiftDataObs.pipe(take(1)).subscribe((data) => {
          this.lastShiftData = data;
        });

          this.dataExportService
            .sendMapToServer(currentMap)
            .pipe(take(1))
            .toPromise()
            .then(
              (mapId) => {
                this.dataExportService._uploadMapId.next(mapId);
                //upload the current map and send alert data to server with the mapId response of upload
                this.dataExportService.sendAlert(
                  new Date(),
                  'globalPressure',
                  {
                    location: regionName,
                    change: percentChange,
                    lastShift: this.lastShiftData,
                  },
                  mapId
                );
              },
              (error) => {
                console.log('Sent Map error in calculate-metrics.service', error);
              }
            );

          if (SettingsService.settings.showNotifications) {
            const notifiactionTime = new Date(new Date().getTime() + 1000);
            const pressureDelta = Math.round((percentChange - 1) * 100);
            this.localNotificationService.sendLocalNotification(
              'Pressure Alert',
              `There was a ${pressureDelta} pct rise in ${regionName} pressure`,
              notifiactionTime,
              2,
              false
            );
            this.showPressureAlert(pressureDelta, regionName, currentMap);
          }
        }
      }
      return Math.round((percentChange - 1) * 100);

  }

  async showPressureAlert(
    pressureChange: number,
    region: string,
    currentMap: MapData
  ) {
    const alert = await this.alertController.create({
      header: 'Pressure Alert',
      subHeader: `There was a rise in ${region} pressure`,
      message: `Detected a ${pressureChange}% change in the ${region} pressure`,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.isPressureAlert = false;
            this.pressureWaitTimerStart = null;
            this.isBackgroundAlert = true;
          },
        },
      ],
    });


    await alert.present();
    setTimeout(() => {
      this.isPressureAlert = false;
      this.pressureWaitTimerStart = null;
      this.isBackgroundAlert = true;
      alert.dismiss();
    }, 300000); //TODO: Log ignored pressure alert
  }

  async showLocalPressureAlert(
    pressureChange: number,
    timeTrigger: number,
  ) {
    const alert = await this.alertController.create({
      header: `Local Pressure Alert`,
      subHeader: `Triggered at ${new Date().toLocaleTimeString()}`,
      message: `Pressure as over ${pressureChange} mmHg for ${timeTrigger} minutes`,
      cssClass: 'alert-pressure',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.isPressureAlert = false;
            this.localAlertWaitTime = null;
            this.isBackgroundAlert = true;
          },
        },
      ],
    });

    await alert.present();
    setTimeout(() => {
      this.isPressureAlert = false;
      this.localAlertWaitTime = null;
      this.isBackgroundAlert = true;

      alert.dismiss();
    }, 300000); //TODO: Log ignored pressure alert

    alert.onDidDismiss().then((data) => {
      this.isPressureAlert = false;
      this.localAlertWaitTime = null;
      this.isBackgroundAlert = true;
    });
  }

  async presentToast() {
    const toast = await this.toastController.create({
      header: 'Pressure Alert!',
      message: 'Reduce pressure or off-load to clear',
      color: 'danger',
      duration: 250,
      position: 'top',
    });
    toast.present();
  }
}

/* eslint-disable no-underscore-dangle */
import { AlertController } from '@ionic/angular';
import { BetweenShiftService } from '../between-shift.service';
import { SitTimersService } from '../sit-timers/sit-timers.service';
import { SettingsService } from 'src/app/settings/settings.service';
import { RecordingService } from '../recording.service';
import { RegionSelectService } from '../map-details/region-select.service';
import { Subscription } from 'rxjs';
import { PressureMatService } from './pressuremat.service';
import { Component, Input, OnInit, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { PressureMat } from './pressuremat.type';
import { MapView } from './mapview.type';
import { CalibrationService } from './calibration.service';
import { CalibrationSectionModel } from '../../modals/calibration-sections/calibration-section.model';
import { Plugins } from '@capacitor/core';
import { DateHelpers } from '../../graphs/date-helper';

const { Storage } = Plugins;

@Component({
  selector: 'app-pressure-mat',
  templateUrl: './pressure-mat.component.html',
  styleUrls: ['./pressure-mat.component.css'],
})
export class PressureMatComponent implements OnInit, OnDestroy{
@Input() id: string;
@ViewChild('mapSection') mapCanvas;

pressureMat: PressureMat;
transformDegrees = 0;
centroidShown: boolean;
mapView: MapView;
selectedRegion: string;
ppiShown: boolean;
showControls: boolean;
showCalibration: boolean;
isCalibrationRunning: boolean;
ppiGraphShown: boolean;

regionSub: Subscription;
calibrationSectionSub: Subscription;
calibrationCounter: number;
calibrationSub: Subscription;
pressureMatSub: Subscription;
calibrationText: string;
sections: CalibrationSectionModel[];
calibrationTimeout: any;
numberShown: any;
canCalibrate: boolean;

  constructor(
    private pressureMatService: PressureMatService,
    private renderer: Renderer2,
    private regionSelectService: RegionSelectService,
    private calibrationService: CalibrationService,
    private recordingService: RecordingService,
    private sitTimersService: SitTimersService,
    private betweenShiftService: BetweenShiftService,
    private alertController: AlertController,
  ) { }

  ngOnDestroy(): void {
    if (this.regionSub) {this.regionSub.unsubscribe();}
    if (this.calibrationSectionSub) {this.calibrationSectionSub.unsubscribe();}
    if (this.calibrationSub) {this.calibrationSub.unsubscribe();}
  }

  ngOnInit(): void {
    this.isCalibrationRunning = false;
    this.calibrationCounter = 0;
    this.showCalibration = false;

    this.sections = SettingsService.settings.calibrationSections.filter(obj => obj.checked === true);
    this.canCalibrate = SettingsService.settings.performCalibration;

    this.showControls = true;
    this.pressureMatService.setColorScheme();
    this.centroidShown = false;
    this.selectedRegion = 'global';
    if (this.id.includes('aggregate') || this.id.includes('editor')) {
      this.showControls = false;
    }
    if (this.pressureMat)
    {
      this.pressureMatService.drawSingleMap(this.pressureMat, this.id);
    }

    this.regionSub = this.regionSelectService.selectedRegion.subscribe(selectedRegion => {
      this.selectedRegion = selectedRegion;
    });


    // Calibration event
    this.calibrationSectionSub = this.calibrationService.sectionCalibrated.subscribe(sectionCalibrate => {

      this.calibrationService._isCalibrating.next(true);
      if (sectionCalibrate) {
        if(!this.isCalibrationRunning) {
          this.calibrationService.resetCalibrationSections();
          this.isCalibrationRunning = true;
          this.showCalibration = true;
          this.recordingService.startShiftRecording('calibration');
          this.centroidShown = true;
          this.calibrationText =`Start Calibration`;
        }
      } if (!sectionCalibrate) {
        this.calibrationCounter += 1;
        if (this.calibrationCounter < this.sections.length) {
          this.calibrationTimeout = setTimeout(() => {
            this.calibrationService.autoCalibration(this.sections[this.calibrationCounter]);
            this.calibrationText =`Shift to the ${this.sections[this.calibrationCounter].name} section`;
          } , 2500); //time to wait between calibration sections
        } else {
          this.calibrationCounter = 0;
          this.showCalibration = false;
          this.recordingService.stopShiftRecording(['calibration'], 'timeCompleted');
          this.isCalibrationRunning = false;
          this.calibrationService._isCalibrating.next(false);
          const startTime = this.sitTimersService.setStartTime();
          this.sitTimersService.startSitPeriodTimer(startTime, SettingsService.settings.shiftInterval, 0);
        }
      }
    });

    this.ppiGraphShown = SettingsService.settings.showPPIDetails;
  }

  stopCalibration() {
    this.calibrationCounter = this.sections.length;
    this.showCalibration = false;
    this.recordingService.stopShiftRecording(['calibration'], 'stopped');
    clearTimeout(this.calibrationTimeout);
    this.calibrationService.stopCalibration();
    this.isCalibrationRunning = false;
    this.calibrationService._isCalibrating.next(false);
    this.calibrationSub.unsubscribe();
    this.calibrationSectionSub.unsubscribe();
    const startTime = this.sitTimersService.setStartTime();
    this.sitTimersService.startSitPeriodTimer(startTime, SettingsService.settings.shiftInterval, 0);
  }

  onResize() {
    if (this.pressureMat)
    {
      this.pressureMatService.drawSingleMap(this.pressureMat, this.id);
    }
  }

  async onSuggestShift(id) {
    this.pressureMatService.setColorScheme();

    if (id === 'live') {
      const directionSuggestion = [];
      const periodPPI = await this.betweenShiftService.getPeriodPPI();
      if (periodPPI.frontPPI < periodPPI.backPPI) {
        directionSuggestion.push('front');
      } else {
        directionSuggestion.push('back');
      }
      if (periodPPI.leftPPI < periodPPI.rightPPI) {
        directionSuggestion.push('left');
      } else {
        directionSuggestion.push('right');
      }
      this.showSuggestionAlert(directionSuggestion, periodPPI);
    }
  }

  onRotate() {
    const startRotation = this.transformDegrees;
    this.transformDegrees = (90 + this.transformDegrees);
    this.mapCanvas.nativeElement.animate(
      [
        {transform: `rotate(${startRotation}deg)`},
        {transform: `rotate(${this.transformDegrees}deg)`}
      ],
      {
        duration: 800,
        delay: 0,
        easing :'ease-in-out'
      });
      this.transformDegrees = this.transformDegrees % 360;
      this.renderer.setAttribute(this.mapCanvas.nativeElement, 'style', `transform: rotate(${this.transformDegrees}deg);`);
  }

  centroidChange(event: any) {
    this.centroidShown = event.detail.checked;
  }
  ppiChange(event: any) {
    this.ppiShown = event.detail.checked;
  }
  numberChange(event: any) {
    this.numberShown = event.detail.checked;
  }

  startCalibration() {
    this.sections = SettingsService.settings.calibrationSections.filter(obj => obj.checked === true);
    this.calibrationService.autoCalibration(this.sections[0]);
    this.sitTimersService.stopSitPeriodTimer();
  }

  async showSuggestionAlert(directionStringArray: string[], periodPPI) {
    //get last shift
    let lastShiftTime = '0';
    let lastShiftString = 'No previous shift';
    const lastShit = await Storage.get({ key: 'lastShift' });
    if (lastShit && lastShit.value){
      lastShiftTime = lastShit.value;
    }
    //calculate time since last shift
    if (lastShiftTime !== '0') {
      const shiftTime = new Date(lastShiftTime);
      const now = new Date();
      const timeDiff = DateHelpers.getDateDiff(shiftTime, now);
      const hoursString = timeDiff.hour < 10 ? '0' + timeDiff.hour.toString() : timeDiff.hour.toString();
      const minutesString = timeDiff.minute < 10 ? '0' + timeDiff.minute.toString() : timeDiff.minute.toString();
      const secondString = timeDiff.second < 10 ? '0' + timeDiff.second.toString() : timeDiff.second.toString();
      lastShiftString = `Time Since Last Shift:
      ${hoursString}:${minutesString}:${secondString}`;
    }
    const periodPPIString = `Front:${periodPPI.frontPPI}<br>
                              Back:${periodPPI.backPPI}<br>
                              Left:${periodPPI.leftPPI}<br>Right:${periodPPI.rightPPI}`;

    const frontLeftColor = this.cssColorFromValue(periodPPI.q2PPI);
    const frontRightColor = this.cssColorFromValue(periodPPI.q1PPI);
    const backLeftColor = this.cssColorFromValue(periodPPI.q3PPI);
    const backRightColor = this.cssColorFromValue(periodPPI.q4PPI);

    const alert = await this.alertController.create({
      header: `Shift to ${directionStringArray} sections`,
      subHeader: `${lastShiftString}`,
      message:  `<div class="suggestion-container">` +
      `<div class=` + frontLeftColor + `>Front Left</div>` +
      `<div class=` + frontRightColor + `>Front Right</div>` +
      `<div class=` + backLeftColor + `>Back Left</div>` +
      `<div class=` + backRightColor + `>Back Right</div>` +
    `</div>`,
      buttons: [
        {
          text: 'OK',
          handler: () => {},
        },
      ],
    });

    await alert.present();
    setTimeout(() => alert.dismiss(), 100000);
  }

  cssColorFromValue(value: number) {
    let color = 'suggestion-box-green';
    if (value < 67) {
      color = 'suggestion-box-green';
    } else if (value >= 67 && value < 134) {
      color = 'suggestion-box-orange';
    } else if (value >= 134) {
      color = 'suggestion-box-red';
    }
    return color;
  }

  getCalibrationData() {
    this.calibrationService.getCalibrationData();
  }
}

/* eslint-disable no-underscore-dangle */
import { SettingsService } from 'src/app/settings/settings.service';
import { MapData } from '../../data-export.service';
import { Injectable } from '@angular/core';
import { PressureMat } from './pressuremat.type';
import { MapView } from './mapview.type';
import { Subject } from 'rxjs';
import { MatrixHelpers } from '../../matrix-helpers';

@Injectable({
  providedIn: 'root'
})
export class PressureMatService {
  gridNeedsUpdate = true;
  regionColors = [];
  defaultGridColor = 'rgba(192,192,192,0.6)';
  mapView: MapView;
  selectedMapColor: string;
  colors = [];

  defaultColors = [
    {red: 255, green: 255, blue: 255},
    {red: 240, green: 240, blue: 240},
    {red: 174, green: 206, blue: 238},
    {red: 128, green: 192, blue: 255},
    {red: 64, green: 160, blue: 255},
    {red: 0, green: 128, blue: 255},
    {red: 0, green: 144, blue: 192},
    {red: 0, green: 160, blue: 128},
    {red: 0, green: 176, blue: 64},
    {red: 0, green: 192, blue: 0},
    {red: 64, green: 208, blue: 0},
    {red: 128, green: 224, blue: 0},
    {red: 192, green: 240, blue: 0},
    {red: 255, green: 255, blue: 0},
    {red: 255, green: 224, blue: 0},
    {red: 255, green: 192, blue: 0},
    {red: 255, green: 160, blue: 0},
    {red: 255, green: 128, blue: 0},
    {red: 255, green: 64, blue: 0},
    {red: 255, green: 0, blue: 0}
];

easeColors = [
  {red: 255, green: 255, blue: 255},
  {red: 240, green: 240, blue: 240},
  {red: 174, green: 206, blue: 238},
  {red: 128, green: 192, blue: 255},
  {red: 64, green: 160, blue: 255},
  {red: 0, green: 128, blue: 255},
  {red: 0, green: 144, blue: 192},
  {red: 0, green: 160, blue: 128},
  {red: 0, green: 176, blue: 64},
  {red: 0, green: 192, blue: 0},
  {red: 64, green: 208, blue: 0},
  {red: 86, green: 224, blue: 0},
  {red: 128, green: 240, blue: 0},
  {red: 192, green: 255, blue: 0},
  {red: 204, green: 255, blue: 0},
  {red: 220, green: 255, blue: 0},
  {red: 220, green: 255, blue: 0},
  {red: 220, green: 220, blue: 0},
  {red: 220, green: 210, blue: 0},
  {red: 220, green: 192, blue: 0}
];

  gray = [];
  // this.setGrayScaleArray();

  opacity = 255;
    colorCount = this.colors.length;
    w0 = [];
    w1 = [];
    w2 = [];
    w3 = [];
    wDegree;

  public _viewData = new Subject<PressureMat>();
  public _newMapData = new Subject<MapData>();
  public _aggregatedMapData = new Subject<MapData>();
  private _centroidData = new Subject<any>();

  private pressureMat: PressureMat;

  constructor(private settingService: SettingsService) {

    this.colors=this.defaultColors;
    this.setColorScheme();
  }

  setColorScheme() {
    this.settingService.get('selectedMapColor').then((color) => {
      if (color.value) {
        this.selectedMapColor =  color.value.trim();
      } else {
        this.selectedMapColor = 'default';
      }
      switch(this.selectedMapColor) {
        case 'default':
           this.colors = this.defaultColors;
          break;
        case 'gray':
          this.colors = this.setGrayScaleArray();
          break;
        default:
          this.colors = this.easeColors;
      }
      this.colorCount = this.colors.length;
      });
  }

  get viewData() {
    return this._viewData.asObservable();
  }

  get newMapData() {
    return this._newMapData.asObservable();
  }

  get aggregatedMapData() {
    return this._aggregatedMapData.asObservable();
  }
  get centroidObs() {
    return this._centroidData.asObservable();
  }


  getMats(): PressureMat[] {
      return;
  }

  getMap(index: number)
  {
      return;
  }

  setGrayScaleArray() {
    const colors = [];
    for (let i = 255; i > 0; i-=12) {
      colors.push({red: i, green: i, blue: i});
    }
    return colors;
  }

  setMap(pressureMap: PressureMat) {
      this.pressureMat = pressureMap;
  }

/**
 * Update canvas size.
 *
 * @param canvas The canvas to draw on.
 * @param size The size of the cell.
 * @param marginTop The top margin of the grid.
 * @param marginLeft The left margin of the grid.
 */
  updateCanvasSize(canvas, size, marginTop, marginLeft) {
        if (!canvas) {
            return;
        }
        if (canvas.height !== size ||
          canvas.width !== size ||
          canvas.style.height !== size + 'px' ||
          canvas.style.width !== size + 'px' ||
          canvas.style.marginLeft !== marginLeft ||
          canvas.style.marginTop !== marginTop) {
            canvas.height = size;
            canvas.width = size;
            canvas.style.height = size + 'px';
            canvas.style.width = size + 'px';
            canvas.style.marginLeft = marginLeft;
            canvas.style.marginTop = marginTop;
            this.gridNeedsUpdate = true;
        }
  }

  /**
   * Update Size of the canvas.
   *
   * @param canvasList The list of canvases to update.
   * @param height The height of the canvas.
   * @param width The width of the canvas.
   */
  updateSize = function(canvasList, height, width) {
      this.mapView = {
          sizes: {
          offsetX: 0,
          offsetY: 0,
          cell: 0
          }
      };
      const size = Math.min(height, width);
          const marginLeft = (width - size) / 2;
          const marginTop = (height - size) / 2;
          let i;
      this.mapView.sizes.cell = Math.floor(size / this.pressureMat.columns);
      this.mapView.sizes.offsetX = size % this.mapView.sizes.cell / 2 ;
      this.mapView.sizes.offsetY = size % this.mapView.sizes.cell / 2;

      for (i = 0; i < canvasList.length; i += 1) {
          this.updateCanvasSize(canvasList[i], size, marginTop + 'px', marginLeft + 'px');
      }
  };

  /**
   * Update the grid color.
   *
   * @param color The color to use.
   * @param region The region to update color
   */

  setGridColor(color, region) {
    if (region || region === 0) {
      if (this.regionColors[region] !== color) {
          this.gridNeedsUpdate = true;
          this.regionColors[region] = color;
      }
    } else {
        for (let i = 0; i < 64; i += 1) {
            if (this.regionColors[i] !== color) {
                this.gridNeedsUpdate = true;
                this.regionColors[i] = color;
            }
        }
    }
  }

    resetGridColor(region) {
        this.setGridColor(this.defaultGridColor, region);
    };

    drawSingleCell(id, x, y, color, h, w, strokeWidth){
      const gridCanvas = document.getElementById(id) as HTMLCanvasElement;
      if (!gridCanvas) {
        return;
      }

      const context = gridCanvas.getContext('2d');
      const regionX = x; // The x location of the region
      const width = this.mapView.sizes.cell;     // The width of a region
      const xShift = regionX * width;
      const regionY = y; // The y location of the region
      const height = this.mapView.sizes.cell;        // The height of a region
      const yShift = regionY * height;

      context.lineWidth = strokeWidth;
      context.strokeStyle = color;
      context.strokeRect(
          this.mapView.sizes.offsetX + xShift,
          this.mapView.sizes.offsetY + yShift,
          width * w,
          height * h
      );
    }

    updateSingleCellCanvas(id) {
      const gridCanvas = document.getElementById(id) as HTMLCanvasElement;
      if (!gridCanvas) {
        return;
      }
      const context = gridCanvas.getContext('2d');

      context.canvas.width+= 0;
    }


      clearSingleCells(id, x, y, size) {
        const gridCanvas = document.getElementById(id) as HTMLCanvasElement;
        if (!gridCanvas) {
          return;
        }
        const context = gridCanvas.getContext('2d');

        const regionX = x; // The x location of the region
        const width = this.mapView.sizes.cell;     // The width of a region
        const xShift = regionX * width;
        const regionY = y; // The y location of the region
        const height = this.mapView.sizes.cell;        // The height of a region
        const yShift = regionY * height;


        context.clearRect(
          this.mapView.sizes.offsetX + xShift,
          this.mapView.sizes.offsetY + yShift,
          width * size + 0.5,
          height * size + 0.5
          );
      }


    drawRegionGrid(context, region) {
      let x;
      let y;
      const regionX = region % (this.pressureMat.columns / this.pressureMat.regionCols); // The x location of the region
      const width = this.mapView.sizes.cell * this.pressureMat.regionCols;            // The width of a region
      const xShift = regionX * width;
      const regionY = Math.floor(region / (this.pressureMat.rows / this.pressureMat.regionRows)); // The y location of the region
      const height = this.mapView.sizes.cell * this.pressureMat.regionRows;           // The height of a region
      const yShift = regionY * height;

      context.strokeStyle = this.regionColors[region];
      for (x = 0; x < this.pressureMat.regionCols; x += 1) {
          context.strokeRect(
              this.mapView.sizes.offsetX + xShift,
              this.mapView.sizes.offsetY + (x * this.mapView.sizes.cell) + yShift,
              width,
              this.mapView.sizes.cell
          );
      }

      for (y = 0; y < this.pressureMat.regionRows; y += 1) {
          context.strokeRect(
              this.mapView.sizes.offsetX + (y * this.mapView.sizes.cell) + xShift,
              this.mapView.sizes.offsetY + yShift,
              this.mapView.sizes.cell,
              height
          );
        }
    }

      drawMapGrid = function(gridCanvas) {
        this.setGridColor(this.defaultGridColor);
          if (!gridCanvas) {
              return;
          }
          if (!this.gridNeedsUpdate) {
              return;
          }
          let region;
              const context = gridCanvas.getContext('2d');
          context.clearRect(0, 0, gridCanvas.width, gridCanvas.height);
          for (region = 0; region < this.pressureMat.regions; region += 1) {
              this.drawRegionGrid(context, region);
              //this.drawSingleCell('live', 1,1, 'red');
          }
          this.gridNeedsUpdate = false;
      };

      drawMap = function(mapCanvas, map) {
          if (!mapCanvas) {
              return;
          }

          this.drawHeatmap(
              mapCanvas.getContext('2d'),
              this.mapView.sizes.offsetX,
              this.mapView.sizes.offsetY,
              map,
              0,
              this.pressureMat.cellMax,
              this.mapView.sizes.cell
          );
      };


    buildWs(degree) {
      //create gradiant of color for heatmap
        if (this.wDegree === degree) {
            return;
        }
        this.wDegree = degree;
        let i; let j; let k; let t;
        for (k = 0, j = 0; j < degree; j += 1) {
            for (i = 0; i < degree; i += 1, k += 1) {
                this.w0[k] = 1 - Math.sqrt(i * i + j * j) / degree;
                if (this.w0[k] < 0) { this.w0[k] = 0; }
                this.w1[k] = 1 - Math.sqrt((degree - i) * (degree - i) + j * j) / degree;
                if (this.w1[k] < 0) { this.w1[k] = 0; }
                this.w2[k] = 1 - Math.sqrt((degree - i) * (degree - i) + (degree - j) * (degree - j)) / degree;
                if (this.w2[k] < 0) { this.w2[k] = 0; }
                this.w3[k] = 1 - Math.sqrt(i * i + (degree - j) * (degree - j)) / degree;
                if (this.w3[k] < 0) { this.w3[k] = 0; }
                t = this.w0[k] + this.w1[k] + this.w2[k] + this.w3[k];
                this.w0[k] /= t;
                this.w1[k] /= t;
                this.w2[k] /= t;
                this.w3[k] /= t;
            }
        }
    }

    cssColor(colorIndex) {
        const color = this.gray[colorIndex];
        return 'rgb(' + color.red + ', ' + color.green + ', ' + color.blue + ')';
    }

    getValueQuad(column, row, map) {
        const index = row * map.columns + column;
        return [
            map.values[index],
            map.values[index + 1],
            map.values[index + map.columns + 1],
            map.values[index + map.columns]
        ];
    }

    fillHeatmap(map, minimum, maximum, heatmap, degree) {
        let quad;
            let i;
            let j;
            let k;
            let row;
            let column;
            let n;
            let value;
            let colorIndex;
            const start = Math.floor(degree / 2) * map.columns * degree + Math.floor(degree / 2);
            const range = maximum - minimum;
        this.buildWs(degree);
        for (row = 0; row < map.rows - 1; row += 1) {
            for (column = 0; column < map.columns - 1; column += 1) {
                quad = this.getValueQuad(column, row, map);
                n = 4 * (start + column * degree + row * map.columns * degree * degree);
                for (k = 0, j = 0; j < degree; j += 1, n += 4 * (map.columns * degree - degree)) {
                    for (i = 0; i < degree; i += 1, k += 1, n += 4) {
                        value = quad[0] * this.w0[k] + quad[1] * this.w1[k] + quad[2] * this.w2[k] + quad[3] * this.w3[k];
                        if (value >= minimum) {
                            if (value >= maximum) {
                                colorIndex = this.colorCount - 1;
                            } else {
                                colorIndex = Math.max(0, Math.floor(this.colorCount * (value - minimum) / range));
                            }

                            heatmap.data[n] = this.colors[colorIndex].red;
                            heatmap.data[n + 1] = this.colors[colorIndex].green;
                            heatmap.data[n + 2] = this.colors[colorIndex].blue;
                            heatmap.data[n + 3] = this.opacity;
                        }
                    }
                }
            }
        }
    }

    drawLegend(context, x, y, height, minimum, maximum, textColor) {
        let i;
        for (i = 0; i < 20; i += 1) {
            context.fillStyle = this.cssColor(this.colorCount - 1 - i);
            context.fillRect(x, y + i * height / 20, 20, height / 20);
        }
        context.strokeRect(x, y, 20, height);
        context.fillStyle = textColor;
        context.font = '12pt sans-serif';
        context.textBaseline = 'top';
        context.fillText(maximum.toString(), x + 25, y);
        context.textBaseline = 'middle';
        context.fillText('mmHg', x + 25, y + height / 2);
        context.textBaseline = 'bottom';
        context.fillText(minimum.toString(), x + 25, y + height);
    }

    drawHeatmap(context, x, y, map, minimum, maximum, interpolation) {
        const height = map.rows * interpolation;
        const width = map.columns * interpolation;
        const heatmap = context.createImageData(width, height);
        this.fillHeatmap(map, minimum, maximum, heatmap, interpolation);
        context.putImageData(heatmap, x, y);
    }

    drawNumberGridCell(id, map){
      const gridCanvas = document.getElementById(id) as HTMLCanvasElement;
      if (!gridCanvas) {
        return;
      }

      const context = gridCanvas.getContext('2d');

      //clear the canvas
      this.clearSingleCells(id, 0, 0, 16);
      // populate each cell in 16 x 16 grid with number
      for (let row = 0; row < map.rows ; row++) {
        for (let column = 0; column < map.columns; column++) {

        const width = this.mapView.sizes.cell;     // The width of a region
        const xShift = column * width;
        const height = this.mapView.sizes.cell;        // The height of a region
        const yShift = row * height;

         const x = this.mapView.sizes.offsetX + xShift;
        const y = this.mapView.sizes.offsetY + yShift;

        const index = row * map.columns + column;
        const value = map.values[index];

          context.font = '12px serif';
          context.fillText(value,
            x + 5, // fill text in center of cell
            y + 15,
        );
        }
      }
    }

    // The returned object
    returnHeatmat()
    {
        return {
            drawHeatmap: this.drawHeatmap,
            drawLegend: this.drawLegend
        };
    };

    drawSingleMap(mapData, id)
    {
      const mapSection = document.getElementById(`mapSection_${id}`);
      const mapCanvas = document.getElementById(`mapCanvas_${id}`);
      const alertCanvas = document.getElementById(`alertCanvas_${id}`);
      const mapGridCanvas = document.getElementById(`gridCanvas_${id}`);
      const mapCentroidCanvas = document.getElementById(`centroidCanvas_${id}`);
      const ppiCanvas = document.getElementById(`ppiCanvas_${id}`);
      const calibarionCanvas = document.getElementById(`calibrateCanvas`);
      const calibrationEditorCanvas = document.getElementById(`canvasEditor_${id}`);
      const top5PPICanvas = document.getElementById(`ppiTop5Canvas_${id}`);
      const numberCanvas = document.getElementById(`numberCanvas_${id}`);

      if (mapSection) {
        this.pressureMat = mapData;
          this.updateSize([mapCanvas, mapGridCanvas, mapCentroidCanvas,
            alertCanvas, ppiCanvas, calibarionCanvas, top5PPICanvas, calibrationEditorCanvas, numberCanvas],
            mapSection.offsetHeight, mapSection.offsetWidth - 8);
      }
      if (mapData) {
          this.drawMap(mapCanvas, mapData);
          const centroid = this.getCentroid(mapData);
          this._centroidData.next(centroid);
          this.drawCentroid(centroid, mapData, id);
          this.drawNumberGridCell(`numberCanvas_${id}`, mapData);

          const average = mapData.values.reduce((a, b) => a + b) / mapData.values.length;
          const ppiData = this.calculatePPI(mapData);
          if (average >= 1) { // make sure the average of the map is greater than 1
            this.drawPPI(ppiData, id);
          } else {
            this.clearSingleCells(`ppiCanvas_${id}`, 0, 0, 16);
            this.updateSingleCellCanvas(`ppiCanvas_${id}`);
          }

      }
      this.drawMapGrid(mapGridCanvas);
    }

    calculatePPI(mapData){
      const pressureData = this.getAllPressureIndexMatrix(
        mapData
      );
      const ppiData = this.getPeakPressureIndex(pressureData);
      return ppiData;
    }

    drawPPI(ppiData, id) {
      this.clearSingleCells(`ppiCanvas_${id}`, 0, 0, 16);
      this.updateSingleCellCanvas(`ppiCanvas_${id}`);
      this.drawSingleCell(`ppiCanvas_${id}`, ppiData.x, ppiData.y, 'red', 2,2, 4);
    }

    drawBoundingBox(x,y,h,w, color, id) {
      this.clearSingleCells(id, 0, 0, 16);
      this.updateSingleCellCanvas(id);
      this.drawSingleCell(id, x, y, color, h, w, 8);
    }

    drawCentroid(centroid, mapData, id) {
      const centroidCircles = [];
      const centroidCount = 5;
      const canvas: HTMLCanvasElement = document.getElementById(`centroidCanvas_${id}`) as HTMLCanvasElement;
        let opacity;
        let ctx: CanvasRenderingContext2D;
        let i;
      if (!canvas) {
        return;
      }
      ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);


      const size = Math.min(canvas.height, canvas.width);
      this.mapView.sizes.cell = Math.floor(size / mapData.columns);
      this.mapView.sizes.offsetX = size % this.mapView.sizes.cell / 2;
      this.mapView.sizes.offsetY = size % this.mapView.sizes.cell / 2;

      const centroidXCell = centroid.xAvg * canvas.width -
        this.mapView.sizes.cell / 2 +
        this.mapView.sizes.offsetX;
      const centroidYCell = centroid.yAvg * canvas.height -
        this.mapView.sizes.cell / 2 +
        this.mapView.sizes.offsetY;

      //this._centroidData.next({x: centroidXCell, y: centroidYCell});

      // Yeah, I know the adjustment is a little magic, but it works.  Try the "single point" dummy
      // mat and track the centroid.
      if (centroid.xAvg && centroid.yAvg) {
        centroidCircles.push({
          scaledX:
            centroidXCell,
          scaledY:
            centroidYCell,
        });
      }

      if (centroidCircles.length > centroidCount) {
        centroidCircles.shift();
      }

      for (i = 0; i < centroidCircles.length; i += 1) {
        opacity = (i + 1) / centroidCircles.length;
        ctx.beginPath();
        ctx.arc(
          centroidCircles[i].scaledX, // * 0.96,
          centroidCircles[i].scaledY, // * 0.96,
          7,
          0,
          2 * Math.PI,
          false
        );
        ctx.fillStyle = 'rgba(0,0,0,' + opacity + ')';
        ctx.fill();
      }
    }


  getCentroid(mapData: MapData) {
    let i = 0;
    let x = 0;
    let y = 0;
    let activeCount = 0;
    let sum = 0;
    let xSum = 0;
    let ySum = 0;
    let max = 0;
    let maxCellLocation;
    let xCell = 0;
    let yCell = 0;
    let xAvg= 0.5;
    let yAvg = 0.5;


    for (i = 0; i < mapData.values.length; i += 1) {
      x = i % mapData.rows + 1;
      y = Math.floor(i / mapData.columns) + 1;


      xSum += x * mapData.values[i];
      ySum += y * mapData.values[i];
      sum += mapData.values[i];

        if (mapData.values[i] > 0) {
            activeCount += 1;
        }

        if (mapData.values[i] > max) {
            max = mapData.values[i];
            maxCellLocation = {x, y};
        }
    }
    const avg = sum / mapData.values.length;
    const activeAvg = sum / activeCount;
    // this.calculateStdDevs(mapData, activeCount);


    if (xSum > 0) {
      xAvg = (xSum / sum) / mapData.rows;
      xCell = (xSum / sum);
    }
    if (ySum > 0) {
        yAvg = (ySum / sum) / mapData.columns;
        yCell = (ySum / sum);
    }

    const data = {
      xAvg,
      yAvg,
      maxCellLocation,
      activeCount,
      activeAvg,
      avg,
      location: {x:xCell, y:yCell},
      values: mapData.values,
    };

    return data;
  }

    getPressureMat() {
        return this.mapView;
    }

  calculateStdDevs(mapData) {
    let i;
    let stdDevSum = 0;
    let stdDevSumActive = 0;
    let stdDevActive = 0;
    let stdDev = 0;
    let activeCount = 0;

    for (const data of mapData) {
      stdDevSum += data.avg;
      if (data.activeCount > 0) {
        activeCount+=1;
        stdDevSumActive += data.activeAvg;
      }
    }

    stdDev = Math.sqrt(stdDevSum / mapData.length);
    stdDevActive = Math.sqrt(stdDevSumActive / activeCount);
    return {
      stdDev,
      stdDevActive,
    };
  }

  analyzeMapData(mapData: MapData[]) {
    const mapDataList = [];
    for(const maps of mapData) {
      const centroid = this.getCentroid(maps);
      mapDataList.push(centroid);
    }
    const stdDevs = this.calculateStdDevs(mapDataList);
  }

/**
 * getAllPressureIndexMatrix - Gets the peak pressure index from a 2d Matrix
 *
 * @param  {Array} mapData - map object
 * @returns {number} - the peak pressure for the region given
 */

  getAllPressureIndexMatrix(mapData) {
    const matrix = MatrixHelpers.arrayToMatrix(
      mapData.values,
      mapData.rows
    );

    const slidingWindowArray = MatrixHelpers.slidingWindow(matrix, 2);

    const slidingPressureMetricsRow = slidingWindowArray.map((currRow, row) => {
      const slidingPressureMetrics = currRow.map((currElement, column) => {
        const ppi =  currElement.flat().reduce((a, b) => a + b) / currElement.flat().length;
        return {x: column, y: row, ppi, data: currElement, date: mapData.date};
      });
      return slidingPressureMetrics;
    });

    //flatten array of objects
    const flattenedPressureMetrics = slidingPressureMetricsRow.flat();

    //get the map in the pressure of sliding windows

    return flattenedPressureMetrics;
  }


  getPeakPressureIndex(flatMap) {
    const ppiOutput = flatMap.reduce((prev, current) => (prev.ppi > current.ppi) ? prev : current, 0);
    return ppiOutput;
  }
}

import { AuthData, AuthService } from 'src/app/auth/auth.service';
import { HttpCacheService } from './http-cache.service';
import {Injectable} from '@angular/core';
import { Plugins } from '@capacitor/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AlertController, ToastController } from '@ionic/angular';

const { Storage } = Plugins;
const TOKEN_KEY = 'token';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  user: AuthData;
  constructor(
    private alertController: AlertController,
    private httpCacheService: HttpCacheService,
    private toastController: ToastController,
    private authService: AuthService
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  return from(Storage.get({key: TOKEN_KEY}))
    .pipe(
    switchMap(token => {
      if (token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token.value) });
      }
      if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
      request = request.clone({
        headers: request.headers.set('Accept', 'application/json')
      });
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {}
          return event;
        }),
          catchError((error: HttpErrorResponse) => {
            console.log('HTTP ERROR', JSON.stringify(error));
            const status =  error.status;
            const message = error.message;
            const reason = error && error.error.reason ? error.error.reason : '';
            this.authService.getUserData().then((user) => {
              console.log('http interceptor user', user);
              if(JSON.parse(user.value).role === 'patient'){
                if (request.url.indexOf('authenticate') === -1
                && (request.method === 'PUT' || request.method === 'POST')) {
                  const result = this.httpCacheService.setStorageRequest(request);
                }
      }
    });
            return throwError(error);
          })
        );
      })
    );
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  async presentAlert(status, message) {
    const header = 'Network Error';
    const subHeader = 'could not communicate with network';
    if(status === 0){
      message = 'no internet connection, using offline mode';
    }

    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons: ['OK']
    });

    await alert.present();
  }
}

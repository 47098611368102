import { IdService } from './id.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network, Plugins } from '@capacitor/core';

const { Storage } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {

  status = null;
  handler;
  loaderElement;

  constructor(
      private httpClient: HttpClient,
      private idService: IdService,) {

    this.getStatus();
    if (this.handler === undefined) {
      console.log('Add Listener ', this.status);
      this.handler = Network.addListener('networkStatusChange', (status) => {
        console.log('Network status changed! ', status);
        const oldStatus = this.status;
        this.status = status;
        if (this.status.connected && !oldStatus.connected) {
          // now connected, before was not connected
        }
        this.status = status;
        if (!this.status.connected) {
          // this.sharedService.showNotification('Network connection has terminated.', 'danger', 5000);
          // not connected
        }
      });
    }
  }

  public async setItem(key, value) {
    return await Storage.set({key, value});
  }

  public async setStorageRequest2(request) {
    const cacheKey = 'reqcache-' + this.idService.generate();
    return await Storage.set({key:cacheKey, value:JSON.stringify(request)});
  }


  public async isValueInStorage(value) {
    const valuesInStorage = [];

    const storageKeys =await Storage.keys().then(async keys => Promise.all(keys.keys.map(async key => {
      // console.log('KEYS:', key);
        if (key.includes('reqcache')) {
            const request = await Storage.get({key}).then(_value => {
              const requestValue = JSON.parse(_value.value);
              requestValue.key = key;
              const timeString = +requestValue.key.split('-')[1];
              requestValue.time = new Date(timeString);
              valuesInStorage.push(requestValue);
            });
        }
      })));
    const valueObj = JSON.parse(value);
    const isValueRepeat = valuesInStorage.filter(e => JSON.stringify(e.body) === JSON.stringify(valueObj.body)).length > 0;
    return isValueRepeat;
  }

  public async setStorageRequest(request) {
    // this.sharedService.showNotification('User is offline. Service Request Cached', 'warning', 2000);
    const isValueInStorage = await this.isValueInStorage(JSON.stringify(request));
    if (!isValueInStorage && request.value !== null) {
      console.log('Caching request', JSON.stringify(request));
      const cacheKey = 'reqcache-' + new Date().getTime() + '-' + request.urlWithParams;
      return Storage.set({key:cacheKey, value:JSON.stringify(request)});
    } else {
      console.log('Request already cached');
    }
  }

  public async get(settingName){
    return await Storage.get({key:`setting:${ settingName }`});
  }

  public async get2(settingName) {
    return await Storage.get({key:settingName});
  }

  public async remove(settingName){
    return await Storage.remove({key:settingName});
  }

  public async getKeys() {
    return await Storage.keys();
  }

  public async getStatus() {
    this.status = await Network.getStatus();
  }


}

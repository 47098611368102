/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  httpHeaders = {'Content-Type': 'application/json',
    responseType: 'text' as 'json'
  }; //text as json to workaround http failur during parse response


  public _filterAlerts = new Subject<any[]>();

  get filterRemindersObs() {
    return this._filterAlerts.asObservable();
  }
  constructor(private httpClient: HttpClient) { }

  fetchAlerts() {
  return this.httpClient.get(
    SettingsService.settings.serverURL + '/alert',
    ).pipe(take(1));
  }

  fetchAlertsWithConstraints(patient?, start?, end?) {
    let params = {};
    if (patient && start && end) {
      params = {patient, start: start.toISOString(), end: end.toISOString()};
    }
    else if (patient) {
      params = {patient};
    }

    const httpOptions = {
      headers: this.httpHeaders,
      params
    };

    return this.httpClient.get(
      SettingsService.settings.serverURL + '/alert', httpOptions).pipe(take(1));
    }

    fetchOutOfChairWithConstraints(patient?, start?, end?) {
    let params = {};
    if (patient && start && end) {
      params = {patient, start: start.toISOString(), end: end.toISOString()};
    }
    else if (patient) {
      params = {patient};
    }

    const httpOptions = {
      headers: this.httpHeaders,
      params
    };

    return this.httpClient.get(
      SettingsService.settings.serverURL + '/outOfChair', httpOptions).pipe(take(1));
    }

    fetchRemindersWithConstraints(patient?, start?, end?) {
    let params = {};
    if (patient && start && end) {
      params = {patient, start: start.toISOString(), end: end.toISOString()};
    }
    else if (patient) {
      params = {patient};
    }

    const httpOptions = {
      headers: this.httpHeaders,
      params
    };

    return this.httpClient.get(
      SettingsService.settings.serverURL + '/reminder', httpOptions).pipe(take(1));
    }

}

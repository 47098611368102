import { PressureMatComponent } from './pressure-mat.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [ CommonModule, IonicModule],
  declarations: [PressureMatComponent],
  exports: [PressureMatComponent]
})
export class PressureMatComponentModule {}

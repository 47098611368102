import { DateHelpers } from 'src/app/graphs/date-helper';

export interface HistoryData {
  date: string;
  maps: string;
  patient: string;
  _id: string;
}

//TODO: USE SWITCH CASE TO SIMPLIFY

export abstract class HistoryGroupHelper {
//group data according to day it was recorded

static groupByDay(data: HistoryData[]) {
  const groups = data.reduce((groups, map) => {
    let date = null;
    if(map.date){
      date = new Date(map.date).toLocaleDateString();
    } else {
      date = new Date(map[0].date).toLocaleDateString();
    }
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(map);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((date) => ({
      date,
      maps: groups[date]
    }));
    return groupArrays;
}

static groupByDayGetTime(data: HistoryData[]) {
  const groups = data.reduce((groups, map) => {
    let dateString = null;
    if(map.date){
      dateString = new Date(map.date).toLocaleDateString();
    } else {
      dateString = new Date(map[0].date).toLocaleDateString();
    }
    if (!groups[dateString]) {
      groups[dateString] = [];
    }
    groups[dateString].push(map);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((dateString) => ({
      dateString,
      maps: groups[dateString],
      data: null,
      date:  new Date(dateString),
    }));
    return groupArrays;
}

static groupByStart(data: any[]) {
  const groups = data.reduce((groups, map) => {
    const date = new Date(map.start).toLocaleDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(map);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((date) => ({
      date,
      maps: groups[date]
    }));
    return groupArrays;
}

static groupByHour(data: HistoryData[]) {
  const groups = data.reduce((groups, map) => {
    const date = new Date(map.date).getHours();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(map);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((hour) => ({
      hour,
      maps: groups[hour]
    }));
  return groupArrays;
}

static groupByMinutes(data: HistoryData[]) {
  const groups = data.reduce((groups, map) => {
    const date = new Date(map.date).getMinutes();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(map);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((minute) => ({
      minute,
      maps: groups[minute]
    }));
  return groupArrays;
}

  static groupBySeconds(data: HistoryData[]) {
    const groups = data.reduce((groups, map) => {
      const date = new Date(map.date).getSeconds();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(map);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((second) => ({
        second,
        maps: groups[second]
      }));
    return groupArrays;
  }

  static groupByIndex(data: HistoryData[]) {
    const groups = data.reduce((groups, map) => {
      const date = Math.floor(new Date(map.date).getTime() / 1000) * 1000; // get second
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(map);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((second) => ({
        second,
        maps: groups[second]
      }));
    const sortGroup = groupArrays.sort((a, b) => (a.second > b.second) ? 1 : -1);

    const actualSeconds = DateHelpers.getEachSecondBetweenTwoDates(
      new Date(+sortGroup[0].second),
      new Date(+sortGroup[sortGroup.length - 1].second));

    const actualSecondsArray = actualSeconds.map((second) => new Date(second).getTime());

    const timeData = [];
    actualSecondsArray.forEach((second) => {
      const index = groupArrays.findIndex((group) => +group.second === second);
      if (index !== -1) {
        timeData.push(groupArrays[index]);
      }
      else {
        timeData.push({ //fill with a empty map
          second: second.toString(),
          maps: [
            {
              date: new Date(second),
              rows: 16,
              columns: 16,
              unit: 'mmHg',
              values: new Array(256).fill(0),
            }
          ]
        });
      }
    });

    return timeData;
  }



  static groupDataByDate(data) {
    const groups = data.reduce((groups, map) => {
      let date = null;
      if(map.date){
        date = new Date(map.date).toLocaleDateString();
      } else {
        date = new Date(map[0].date).toLocaleDateString();
      }
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(map);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => ({
        date,
        data: groups[date]
      }));
      return groupArrays;
  }
}

/* eslint-disable no-underscore-dangle */
import { HistoryService } from '../../history.service';
import { SettingsService } from 'src/app/settings/settings.service';
import { PressureMatService } from './pressuremat.service';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MapView } from './mapview.type';
import { map, take } from 'rxjs/operators';
import { CalibrationSectionModel } from '../../modals/calibration-sections/calibration-section.model';

class BoundingBox {
  top: number;
  left: number;
  bottom: number;
  right: number;
  width: number;
  height: number;
}

@Injectable({
  providedIn: 'root',
})
export class CalibrationService {
  calbirateTimerSub: Subscription;
  count: number;
  samplesNeeded: number;
  mapView: MapView;
  centroidSub: Subscription;
  centroid: any;

  calibrationSections;

  public _isCalibrating = new Subject<boolean>();
  private _sectionCalibrating = new Subject<boolean>();
  private secondsToCalibrate = 10;
  private recordedShiftList: any;

  get isCalibrating() {
    return this._isCalibrating.asObservable();
  }

  get sectionCalibrated() {
    return this._sectionCalibrating.asObservable();
  }

  constructor(
    private pressureMatService: PressureMatService,
    private historyService: HistoryService,
    private settingsService: SettingsService
  ) {
    this.recordedShiftList = [];
    this.resetCalibrationSections();
  }

  resetCalibrationSections() {
    this.settingsService.getObject('calibrationSections').then((calibrationSections) => {
      this.calibrationSections = calibrationSections.filter(obj => obj.checked === true);
    });
  }

  setCalibrationBounds(x, y, width, height): BoundingBox {
    const bounds = {
      top: y,
      left: x,
      bottom: y + height + 1,
      right: x + width + 1,
      width,
      height,
    };
    return bounds;
  }

  updateTextCanvas(text) {
    const canvas: HTMLCanvasElement = document.getElementById(
      `calibrateCanvas`
    ) as HTMLCanvasElement;
    let opacity;
    let ctx: CanvasRenderingContext2D;
    let i;
    if (!canvas) {
      return;
    }

    ctx = canvas.getContext('2d');
    ctx.font = '40px Arial';
    const textMeasure = ctx.measureText(text);

    ctx.strokeStyle = 'red';
    ctx.clearRect(canvas.width / 2 - 40, canvas.height / 2 - 40, 80, 60);

    ctx.textAlign = 'center';
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);
  }

  async stopCalibration() {
    this.count = 0;
    if (this.centroidSub) {
      this.centroidSub.unsubscribe();
    }
    this.pressureMatService.clearSingleCells(
      `calibrateCanvas`,
      -1,
      -1,
      20
    );
    this._sectionCalibrating.next(false);
  }

  async autoCalibration(section: CalibrationSectionModel) {
    if (this.centroidSub) {
      this.centroidSub.unsubscribe();
    }
    this.samplesNeeded =
      this.secondsToCalibrate;
    this.count = this.samplesNeeded;

    this._sectionCalibrating.next(true);
    const boundingBox = this.setCalibrationBounds(section.bounds.x, section.bounds.y, section.bounds.width, section.bounds.height);

    this.centroidSub = this.pressureMatService.centroidObs.subscribe(
      async (centroid) => {
        this.pressureMatService.drawBoundingBox(
          boundingBox.left,
          boundingBox.top,
          boundingBox.height,
          boundingBox.width,
          'purple',
          'calibrateCanvas'
        );
        this.centroid = centroid;
        if (boundingBox) {
          if (
            boundingBox.top <= this.centroid.location.y &&
            this.centroid.location.y <= boundingBox.bottom &&
            this.centroid.location.x >= boundingBox.left &&
            this.centroid.location.x <= boundingBox.right
          ) {
            // Point is in bounding box
            await setTimeout(() => {
              this.count -= 1;
            }, 1000);
            this.updateTextCanvas(`${this.count}`);
          }
        }
        if (this.count <= 0) {
          this.pressureMatService.clearSingleCells(
            `calibrateCanvas`,
            -1,
            -1,
            20
          );
          this._sectionCalibrating.next(false);
          this.centroidSub.unsubscribe();
        }
      }
    );
  }

  getCalibrationData() {
    this.historyService
      .fetchPatientShifts(
        SettingsService.settings.userId,
        null,
        null,
        'calibration'
      )
      .pipe(
        take(1),
        map((success) => {
          this.recordedShiftList = success;
          if (this.recordedShiftList.length > 0) {
            for (const shift of this.recordedShiftList) {
            }
          }
        })
      )
      .subscribe();
  }

  analyzeCalibrationData(centroidData: any[]) {
    for (const boundingBox of this.calibrationSections) {
      if (
        boundingBox.bb.top <= this.centroid.location.y &&
        this.centroid.location.y <= boundingBox.bb.bottom &&
        this.centroid.location.x >= boundingBox.bb.left &&
        this.centroid.location.x <= boundingBox.bb.right
      ) {
        // Point is in bounding box
        this.count -= 1;
        this.updateTextCanvas(`${this.count}`);
      }
    }
  }
}

/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  public _isOnline = new Subject<boolean>();
  public _networkType = new Subject<string>();;

  get isOnlineObs() {
    return this._isOnline.asObservable();
  }

  get networkTypeObs() {
    return this._networkType.asObservable();
  }

  constructor() { }
}

/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegionSelectService {
  public _selectedRegion = new Subject<string>();

  get selectedRegion() {
    return this._selectedRegion.asObservable();
  }


  constructor() { }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { SettingsService } from '../settings/settings.service';


export interface ClinicianDB {
  admin: boolean;
  patients: any[];
  __v: number;
  _id: string;
  first: string;
  last: string;
  email: string;
}

export interface NewClinician {
  first: string;
  last: string;
  email: string;
  password: string;
  admin: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ClinicianManagementService {


  httpHeaders = {headers:
    new HttpHeaders({'Content-Type': 'application/json'}),
    responseType: 'text' as 'json'}; //text as json to workaround http failur during parse response


  constructor(private httpClient: HttpClient) { }

  getClinicianList() {
    return this.httpClient.get<ClinicianDB[]>(
      SettingsService.settings.serverURL + `/clinicians`,
    );
  }

  addNewClinician(newClinician: NewClinician) {
      return this.httpClient.post(SettingsService.settings.serverURL + '/clinicians', newClinician, this.httpHeaders);
  }

  resetClinicianPassword(newPassword: string, clinician: ClinicianDB) {
    return this.httpClient.post(
      SettingsService.settings.serverURL + '/clinicianpass/',
      {newPassword, email: clinician.email},
      this.httpHeaders
    );
  }

  deleteClinician(clinician: ClinicianDB) {
    const params = new HttpParams().set('email', clinician.email);
    return this.httpClient.delete(
      SettingsService.settings.serverURL + '/clinicians/',
      {params},
    ).pipe(take(1));
  }

}

/* eslint-disable no-underscore-dangle */
import { Injectable, OnDestroy } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Subject, Subscription } from 'rxjs';
const { Storage } = Plugins;

export interface ShiftData {
  time: number;
  duration: number;
  prompted: boolean;
}

interface WeekShifts {
  day: number;
  promptedCount: number;
  unpromptedCount: number;
}

interface WeekDurations {
  day: number;
  shiftData: ShiftData;
}

interface LastShiftData {
  date: Date;
  causeString: string;
}

@Injectable({
  providedIn: 'root',
})
export class ShiftService implements OnDestroy{
  public _isShift = new Subject<boolean>();

  shiftInterval: number;
  progressInterval: number;
  isSitPeriodRunning: boolean;
  periodString: string;
  targetShiftTimeString: any;
  time: number;
  timeString: any;
  weekShifts: WeekShifts[] = [];
  weekDurations: WeekDurations[] = [];
  weightShiftId: string;
  mapSequenceId: string;
  reminderId: string;
  public _isShiftRunning = new Subject<boolean>();
  public _isSitting = new Subject<boolean>();

  private _shiftTimer = new Subject<number>();
  private _isCalibration = new Subject<boolean>();
  private _lastShiftData = new Subject<LastShiftData>();

  private shiftingSub: Subscription;
  private weightShiftIdSub: Subscription;
  private mapSequenceIdSub: Subscription;
  private reminderIdSub: Subscription;

  constructor(
    ) {}


  get lastShiftDataObs() {
    return this._lastShiftData.asObservable();
  }

  get isShiftRunningObs() {
    return this._isShiftRunning.asObservable();
  }

  get shiftTimerObs() {
    return this._shiftTimer.asObservable();
  }

  get isShiftObs() {
    return this._isShift.asObservable();
  }

  get isSittingObs() {
    return this._isSitting.asObservable();
  }



  get isCalibrationObs() {
    return this._isCalibration.asObservable();
  }

  ngOnDestroy(): void {
    if (this.shiftingSub) {
      this.shiftingSub.unsubscribe();
    }
    if (this.weightShiftIdSub) {
      this.weightShiftIdSub.unsubscribe();
    }
    if (this.mapSequenceIdSub) {
      this.mapSequenceIdSub.unsubscribe();
    }
    if (this.reminderIdSub) {
      this.reminderIdSub.unsubscribe();
    }
  }

  clearShiftDaysData() {
    this.weekShifts = [];
    this.weekDurations = [];
  }
}

import { MapOutput } from './../../history.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, NavParams } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { HttpParams, HttpClient } from '@angular/common/http';
import { PressureMatDB } from 'src/app/clinician/patient-list/pressure-map.type';
import { SettingsService } from 'src/app/settings/settings.service';


interface DayMaps {
  date: string;
  maps: MapOutput[];
}


@Component({
  selector: 'app-history-shift-modal',
  templateUrl: './history-shift-modal.page.html',
  styleUrls: ['./history-shift-modal.page.scss'],
})
export class HistoryShiftModalPage implements OnInit {

  @Input() currentTimeIndex: number;
  @ViewChild(IonContent, {static: false}) content: IonContent;

  modalTitle: string;
  sequenceId: string;
  mapData: MapOutput[];
  modalData: MapOutput[];
  historyInfo: any;
  isMobileApp = false;
  dateString: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    console.table(this.navParams);
    this.sequenceId = this.navParams.data.paramID;
    this.dateString = this.navParams.data.paramStartTime;
    this.modalTitle = this.navParams.data.paramTitle;
    this.mapData =  this.navParams.data.paramMapData;


    if (this.navParams.data.paramHistoryInfo) {
      this.historyInfo = this.navParams.data.paramHistoryInfo;
    } else {
      this.isMobileApp = true;
      this.getMapSequence(this.sequenceId).then(mapSequences => {
        this.mapData = mapSequences; ;
      });
    }
  }

  async getMapSequence(mapSequenceId: string): Promise<any> {
    let params = new HttpParams();
    params = params.append('id', mapSequenceId.toString());
    const response = await this.httpClient
      .get<PressureMatDB[]>(
        SettingsService.settings.serverURL + `/mapsequence`,
        { params }
      )
      .pipe(take(1))
      .toPromise();
    return response;
  }

  async closeModal() {
    const onClosedData = 'Wrapped Up!';
    await this.modalController.dismiss(onClosedData);
  }
}

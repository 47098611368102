/* eslint-disable no-underscore-dangle */
import { BetweenShiftService } from './../between-shift.service';
import { LocalNotificationService } from '../../local-notification.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SettingsService } from 'src/app/settings/settings.service';
import { Plugins } from '@capacitor/core';
import { ShiftDetectionService } from '../shift-detection.service';
import { take, tap } from 'rxjs/operators';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class SitTimersService {

  elapsedTime = 0;
  started = false;
  timeString: string;

  private sittingInterval: any;
  private _sitTimer = new Subject<number>();
  private isShiftDetecting = false;

  constructor(
    private localNotificationService: LocalNotificationService,
    private shiftDetectionService: ShiftDetectionService,
    private betweenShiftService: BetweenShiftService
    ) { }

  get sitTimerObs() {
    return this._sitTimer.asObservable();
  }

  setNotifications(startTime: string, interval: number) {
    if (SettingsService.settings.showNotifications) {
      this.localNotificationService.setAllDayNotifications(+startTime, interval);
    }
  }

  startSitPeriodTimer(startTime: string, interval: number, seconds: number) {
    if (this.sittingInterval) {
      clearInterval(this.sittingInterval);
    }
    //set up notifications
    if (SettingsService.settings.showNotifications) {
      //introduce a 30 second wait time to allow notification to be sent before resetting all notifications
      setTimeout(() => {
            this.localNotificationService.setAllDayNotifications(+startTime, interval);
        }, 30000);
    } else {
      this.localNotificationService.clearReminderNotifications();
    }
    //const source = timer(0, 1000);
    let prevTickTimestamp = Date.now();
    this.sittingInterval = setInterval(async () => {
      const currentTickTimestamp = Date.now();
      const delta = currentTickTimestamp - prevTickTimestamp;
      prevTickTimestamp = currentTickTimestamp;
      seconds += Math.round(delta / 1000);
      //only show alert if not activelty shift detected or out of chair
      this.shiftDetectionService.isSActivityDetectedObs.pipe(
        take(1),
        tap((isDetected) => {
          this.isShiftDetecting = isDetected;
        })
      ).subscribe();
      if (seconds >= interval && !this.isShiftDetecting) {
        // Finish sit, trigger a shift reminder
        clearInterval(this.sittingInterval);
        const start = this.setStartTime();


        const directionSuggestion = [];
        const periodPPI = await this.betweenShiftService.getPeriodPPI();
        if (periodPPI.frontPPI < periodPPI.backPPI) {
          directionSuggestion.push('front');
        } else {
          directionSuggestion.push('back');
        }
        if (periodPPI.leftPPI < periodPPI.rightPPI) {
          directionSuggestion.push('left');
        } else {
          directionSuggestion.push('right');
        }
        this.localNotificationService.presentShiftReminderConfirm(directionSuggestion, periodPPI);
        this.startSitPeriodTimer(start, SettingsService.settings.shiftInterval, 0);
      }
      this._sitTimer.next(seconds);
    }, 1000);
  }

  stopSitPeriodTimer() {
    if (this.sittingInterval) {
      clearInterval(this.sittingInterval);
    }
  }


setStartTime() {
  const timeString = new Date().getTime().toString();
  Storage.set({ key: 'collection_start', value: timeString });
  return timeString;
}

getStartTime() {
  return Storage.get({ key: 'collection_start' });
}


millisecondsToStr(milliseconds) {
  // TIP: to find current time in milliseconds, use:
  // var  current_time_milliseconds = new Date().getTime();

  function numberEnding(number: number) {
      return (number > 1) ? 's' : '';
  }

  let temp = Math.floor(milliseconds / 1000);
  const years = Math.floor(temp / 31536000);
  if (years) {
      return years + ' year' + numberEnding(years);
  }
  //TODO: Months! Maybe weeks?
  const days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
      return days + ' day' + numberEnding(days);
  }
  const hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
      return hours + ' hour' + numberEnding(hours);
  }
  const minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
      return minutes + ' minute' + numberEnding(minutes);
  }
  const seconds = temp % 60;
  if (seconds) {
      return seconds + ' second' + numberEnding(seconds);
  }
  return 'less than a second'; //'just now' //or other string you like;
}

  secondsToMMSS(seconds: number): string {
    const minutes = Math.floor(seconds/60);
    const secondsLeft = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${secondsLeft < 10 ? '0': ''}${secondsLeft}`;
  }

  async getElapsedSeconds() {
    let elapsedSeconds = 0;
    return this.getStartTime().then((time) => {
      if(time && time.value)
      {
        const now = new Date().getTime();
        const startTime = +time.value;
        elapsedSeconds = Math.floor((now - startTime) / 1000);
        return elapsedSeconds;
      } else {
        return 0;
      }
    });
  }
}

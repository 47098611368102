import { NetworkService } from './network.service';
/* eslint-disable no-underscore-dangle */
import { LocalNotificationService } from './local-notification.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { Capacitor, Plugins } from '@capacitor/core';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { SettingsService } from './settings/settings.service';
import { NOTIFICATION_IDS } from './constants';
import { Network } from '@capacitor/core';
import { DataExportService } from './data-export.service';
import { LocalNotification, LocalNotificationSchedule, NotificationChannel } from '@capacitor/core';


const { App, BackgroundTask, LocalNotifications } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  watchConnected: boolean;
  authenticated: boolean;
  private sub: Subscription;
  private prevAuthState = false;



  constructor(
    private authService: AuthService,
    private router: Router,
    private platform: Platform,
    private localNotificationService: LocalNotificationService,
    private settingService: SettingsService,
    private backgroundMode: BackgroundMode,
    private alertController: AlertController,
    private networkService: NetworkService,
    private dataExportService: DataExportService,
    private ngZone: NgZone,
) {
  }

  ngOnInit() {
    if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
      this.settingService.get('selectedTheme').then((color) => {
        if (color.value) {
          document.body.setAttribute('color-scheme', color.value.trim());
        } else{
          document.body.setAttribute('color-scheme', 'light');
        }
        });
        this.backgroundMode.enable();
        this.backgroundMode.on('activate').subscribe(() => {
          this.backgroundMode.disableWebViewOptimizations();
          this.backgroundMode.disableBatteryOptimizations();
        });
        LocalNotifications.requestPermission();
    }

    this.settingService._appState.next('foreground');

    this.sub = this.authService.userIsAuthenticated.subscribe((isAuth) => {
      this.authenticated = isAuth;
      if (!isAuth && this.prevAuthState !== isAuth) {
        this.router.navigateByUrl('/auth');
      }
      this.prevAuthState = isAuth;
    });

    Network.getStatus().then((connection) => {
      this.networkService._isOnline.next(connection.connected);
      this.networkService._networkType.next(connection.connectionType);

      if (connection.connectionType === 'wifi'){
        //this.dataExportService.sendLocalRecordingFiles();
        this.ngZone.run(() => {
          this.dataExportService.sendCachedHTTPRequests();
        });
      }
    });

    Network.addListener('networkStatusChange', connection => {
      this.networkService._isOnline.next(connection.connected);
      this.networkService._networkType.next(connection.connectionType);
    });


    App.addListener('appStateChange', (state) => {
      if (!state.isActive && (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') ) {
        this.settingService._appState.next('background');
        // const taskId = BackgroundTask.beforeExit(async () => {
        //   const start = new Date().getTime();

          // this.showAlert();

          // Must call in order to end our task otherwise
          // we risk our app being terminated, and possibly
          // being labeled as impacting battery life
          // BackgroundTask.finish({
          //   taskId,
          // });
        // });
      } else {
        this.settingService._appState.next('foreground');
      }
    });

    if (SettingsService.settings.dailyNotifications) {
      this.settingService.get('dailyAlertTime').then((dailyAlertstart) => {
        const today = new Date();
        let tommorrowAlertTime = null;
        if (!dailyAlertstart || !dailyAlertstart.value) {
          // default value
          tommorrowAlertTime = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() + 1,
            SettingsService.settings.dailyStartTime.getHours(),
            SettingsService.settings.dailyStartTime.getMinutes()
          );
          this.settingService.set(
            'dailyAlertTime',
            tommorrowAlertTime.toISOString()
          );
        } else {
          if (new Date(dailyAlertstart.value) <= today || !tommorrowAlertTime) {
            // date is not today reset start time to be todays date
            tommorrowAlertTime = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 1,
              SettingsService.settings.dailyStartTime.getHours(),
              SettingsService.settings.dailyStartTime.getMinutes()
            );
            this.settingService.set(
              'dailyAlertTime',
              tommorrowAlertTime.toISOString()
            );
          }
        }
        this.localNotificationService.sendLocalNotification(
          'Awshift',
          'Remember to shift today!',
          tommorrowAlertTime.getTime(),
          NOTIFICATION_IDS.dailyNotification,
          'day'
        );
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (Capacitor.isPluginAvailable('SplashScreen')) {
        Plugins.SplashScreen.hide();
      }
    });
  }

  // async showAlert() {
  //   const alert = await this.alertController.create({
  //     header: 'Awshift',
  //     subHeader: `Keep app open in background to keep monitoring pressure`,
  //     message: `If you decide to close the app, you will still get shift reminders if enabled in settings`,
  //     buttons: [
  //       {
  //         text: 'OK',
  //         handler: () => {},
  //       },
  //     ],
  //   });

  //   await alert.present();
  //   setTimeout(() => alert.dismiss(), 10000);
  // }

  onLogout() {
    this.authService.logout();
  }
}

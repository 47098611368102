
export abstract class MatrixHelpers{

  // Returns average pressure map of a 2D matrix
  static averagePressureMatrix(mapMatrix: number[][]) {
    const flatMap = mapMatrix.reduce((accumulator,value) => accumulator.concat(value), []);
    let sum = 0;
    for (const value of flatMap) {
      sum += value;
    }
    const result = sum / flatMap.length;
    return result;
  }

  // Returns average pressure map of a region
  static averagePressure(flatMap) {
    let sum = 0;
    for (const value of flatMap.values) {
      sum += value;
    }
    const result = sum / flatMap.values.length;
    return result;
  }

  static mapListAverage(array: any[]) {
    const result = [];
    // get average of arrays of data into single array for aggregate map
    for (let i = 0; i < array[0].values.length; i++) {
      let num = 0;
      //still assuming all arrays have the same amount of numbers
      for (const dataPoint of array) {
        num += dataPoint.values[i];
      }
      result.push(Math.round(num / array.length));
      }
      return result;
  }

  // Returns average of array of numbers
  static findContiniousAverage(arr: number[], num: number)
  {
    if(num > arr.length){
        return [];
    };
    const res = [];
    let sum = 0;
    let left = 0;
    let right = 0;
    for(; right < num; right++){
        sum += arr[right];
    };
    res.push(sum / num);
    for(; right < arr.length; right++, left++){
        sum -= arr[left];
        sum += arr[right];
        res.push(sum / num);
    };
    return res;
  };

  // Returns minimum difference between any pair
  static findMaxDiff(arr, n)
  {
      // Initialize difference as infinite
      let diff = 0;

      // Find the max diff by comparing difference
      // of all possible pairs in given array
      for (let i=0; i<n-1; i++) {
        for (let j=i+1; j<n; j++) {
          if (Math.abs((arr[i] - arr[j]) ) > diff)
          {
            diff = Math.abs((arr[i] - arr[j]));
          }
        }
      }
      return diff;
  }

  static rotateMap90C(source) {
    const sourceArray = MatrixHelpers.arrayToMatrix(source, 16);
    // get the dimensions of the source matrix
    const M = sourceArray.length;
    const N = sourceArray[0].length;
    // create a new NxM destination array
    const destination = new Array(N);
    for (let i = 0; i < N; i++) {
      destination[i] = new Array(M);
    }
    // start copying from source into destination
    for (let i = 0; i < N; i++) {
      for (let j = 0; j < M; j++) {
        destination[i][j] = sourceArray[M - j - 1][i];
      }
    }
    // return the destination matrix
    const flattenDestination = destination.reduce((accumulator,value) => accumulator.concat(value), []);
    return flattenDestination;
  };

  // Returns windowed array of defined size
  static slicingWindows(arr, size) {
      if (size > arr.length) {
          return arr;
      }
      const result = [];
      const lastWindow = arr.length - size;
      for (let i = 0; i <= lastWindow; i += 1) {
          result.push(arr.slice(i, i + size));
      }
      return result;
  };

  static filterNonzero(maps) {
    return maps.values.some(value => value !== 0);
  }


  /**
   * arraytoMatrix - Converts an array to a 2d Matrix
   *
   * @param arr - Array to convert
   * @param width - Width of the matrix
   * @returns {Array} - 2d Matrix
   */

  static arrayToMatrix(arr, width) {
    const toMatrix = arr.reduce(
      (rows, key, index) =>
        (index % width === 0
          ? rows.push([key])
          : rows[rows.length - 1].push(key)) && rows,
      []
    );
    return toMatrix;
  }


  /**
   * slidingWindow - Slides a window across an array
   *
   * @param mapMatrix - 2d Matrix of map data
   * @param size - Size of the sliding window
   * @returns {Array} - 2d Matrix of sliding window
   */

  static slidingWindow(mapMatrix: number[][], size: number) {
    // returns a sliding 2D matrix of windows of size for all rows
    const rowCount = mapMatrix.length;
    const colCount = mapMatrix[0].length;

    const rows2x2 = [];
    for (const row of mapMatrix) {
      const windows = this.toWindows(row,size);
      rows2x2.push(windows);
    }
    const cols2x2 = [];
    for (let index = 0; index < rows2x2.length - 1; index++) {
      const cols = this.getColumn(rows2x2, index);
      cols2x2.push(cols);
    }

    //slide cols array at index
    const filterCols2x2 = cols2x2.slice(0,colCount - 1);

    const localRegions = [];
    for (const col of filterCols2x2) {
      const windows = this.toWindows(col,size);
      localRegions.push(windows);
    }
    // This translates the matrix from (c x r) to (r x c)
    const correctedWindowedRegions = localRegions[0].map((_, colIndex) => localRegions.map(row => row[colIndex]));
    return correctedWindowedRegions;
  }

  /**
   * toWindows - Converts an array to a 2d Matrix
   *
   * @param inputArray - array
   * @param size - size of the windows
   * @returns {Array} - 2d Matrix of sliding window
   */

  static toWindows(inputArray, size) {
    return Array.from(
      {length: inputArray.length - (size - 1)}, //get the appropriate length
      (_, index) => inputArray.slice(index, index+size) //create the windows
    );
  }

  /**
   * getColumn - get the column of data
   *
   * @param anArray - input 2D array
   * @param columnNumber - number of column to return
   * @returns {Array} - Array of selected column data
   */
  static getColumn(anArray, columnNumber) {
    return anArray.map((row) => row[columnNumber]);
  }
}

/* eslint-disable no-underscore-dangle */
import { DateHelpers } from 'src/app/graphs/date-helper';
import { IdService } from './id.service';
import { LocalStorageService } from './local-storage.service';
import { Plugins } from '@capacitor/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, forkJoin, Subject, from} from 'rxjs';
import { DATA_DIRS } from './constants';
import { SettingsService } from './settings/settings.service';
import {concatAll, finalize, map, take, tap} from 'rxjs/operators';
import { ToastController } from '@ionic/angular';

const {Storage } = Plugins;

interface PostData {
  type: string;
  data: LoginData;
  date: Date;
}

interface LoginData {
  successful: boolean;
}

export interface MapData {
  _id?: string;
  date: string;
  rows: number;
  columns: number;
  unit: string;
  values: number[];
  patient?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DataExportService {

  limit = 4;
  ids = [];
  mapCount = 0;

  httpHeaders = {headers:
    new HttpHeaders({'Content-Type': 'application/json'}),
    responseType: 'text' as 'json'}; //text as json to workaround http failur during parse response

  mapData: MapData = {
    date: new Date().toISOString(),
    rows: 16,
    columns: 16,
    unit: 'mmHg',
    values: new Array(256).fill(0),
  };

  public _sequenceId = new Subject<string>();

  public _weightShiftId = new Subject<string>();

  public _reminderId = new Subject<string>();
  weightShiftId: string;
  reminderId: string;
  eventId: string;
  retrySendCacheCount = 0;

  get reminderIdObs() {
    return this._reminderId.asObservable();
  }

  get weightShiftIdObs() {
    return this._weightShiftId.asObservable();
  }

  get sequenceIdObs() {
    return this._sequenceId.asObservable();
  }

  public _uploadMapId = new Subject<string>();

  get uploadMapIdObs() {
    return this._uploadMapId.asObservable();
  }

  constructor(
    private httpClient: HttpClient,
    private idService: IdService,
    private localStorageService: LocalStorageService,
    private toastController: ToastController
    ) {
  }

  uploadLocalStorage(dir: string, file: string) {
    const saveMapList = this.localStorageService.readFileLocalStorage(dir, file).then((savedMaps) => {
      this.sendMapsToServer(savedMaps, ['normal']);
    });
  }

/**************************************************
 * Send a alert log info to the server
**************************************************/
  sendAlert(date: Date, type: string, data?: any, mapId?: string){
    const alertData = {time:date, type:type, data:data, map:mapId};
    return this.httpClient.post<string>(SettingsService.settings.serverURL + '/alert', alertData, this.httpHeaders)
    .subscribe(result => {
        const weightShiftResponse = result.split('"').join('');
        this._weightShiftId.next(weightShiftResponse);
      }, err => {
      });
  }

  /**************************************************
   * Send a weight shift info to the server
  **************************************************/
  sendWeightShift(shiftData) {
    return this.httpClient.post<string>(SettingsService.settings.serverURL + '/weightShift', shiftData, this.httpHeaders);
  }

  /**************************************************
   * Send a shift data to the server
  **************************************************/
uploadWeightShift(action: string, cause: string) {
  if (action ==='start')
  {
    this.weightShiftId = this.idService.generate();
    const shiftData = {weightShiftId:this.weightShiftId, action, cause};
    return this.sendWeightShift(shiftData)
          .pipe(take(1),
          map(
            (result) => this.weightShiftId,
            (err) => {
              console.log('weightShift error in data-export: ', err);
            }
          ));
  } else { //End shift
    const responseShiftData = {
      action,
      cause,
      weightShiftId: this.weightShiftId,
      maps: this.eventId,
      reminderId: this.reminderId
    };
    return this.sendWeightShift(responseShiftData);
  }
}

  /**************************************************
   * SEND LONG MAP SEQUENCE TO SERVER
   * ************************************************/
  sendLongMapSequence(mapData: MapData[], task: string[]) {
      const date = mapData[0].date;
      const end = mapData[mapData.length - 1].date;
      this.eventId = this.idService.generate();
      const mapSequenceIds = mapData.map((mapObj) => mapObj._id);
      const recordData = {
        date,
        maps: this.eventId,
        end,
        task,
      };
      this._sequenceId.next(this.eventId);
      return this.httpClient
      .post<string>(SettingsService.settings.serverURL + '/mapEvent', { mapData, eventId:this.eventId, date, end, task }, this.httpHeaders)
      .pipe(
      tap(
        (resp) => {
        // console.log('mapEvents success', resp);
      }, (err) => {
        console.log('mapEvents sequence error: ', err);
      }),
      finalize(() => {
        this.uploadRecord(recordData);
      })).subscribe();
  }

  /**************************************************
   * Send a map sequence to the server
  **************************************************/
  sendMapSequence(mapIds: string[], date: string, end: string, task: string[]) {
    return this.httpClient
      .post<string>(SettingsService.settings.serverURL + '/mapSequence', { maps: mapIds })
      .subscribe((resp) => {
        const data = {
          date,
          maps: [resp],
          end,
          task,
        };
        this._sequenceId.next(resp);
        this.uploadRecord(data);
      });
  }

  /**************************************************
   * Send a map to the server
   **************************************************/
  sendMapToServer(mapData: MapData) {
    const _id = this.idService.generate();
    if (!mapData) {
      return;
    }
    return this.httpClient.post<string>(SettingsService.settings.serverURL + '/map', {
      _id,
      date: mapData.date,
      rows: mapData.rows,
      columns: mapData.columns,
      unit: mapData.unit,
      values: mapData.values
    });
  };

  //TODO add all maps when adding sequnce REMOVE THIS
  addMapsItems(maps: MapData[]): Observable<any> {
    const multiPuts = [];
    maps.forEach((singleMap) => {
      multiPuts.push(this.addMapItem(singleMap));
    });
    return forkJoin(multiPuts);
  }

  addMapItem(newMap: MapData): Observable<any> {
    return this.httpClient.post<string>(SettingsService.settings.serverURL + '/map', newMap);
  }

  //TODO add all maps when adding sequnce REMOVE THIS
  sendMapsToServer(maps: MapData[], task: string[]): Observable<any> {
    return this.addMapsItems(maps).pipe(
      take(1),
      map(resp => {
        //resp is array of map id's
      const date= maps[0].date;
      const end = maps[maps.length - 1].date;
      this.sendMapSequence(resp, date, end, task);
      return resp;
    }, (err) => {
      console.log(err);
      return false;
      })
    );
  }

/**************************************************
 * Send reminders to the server
**************************************************/
  sendReminderToServer(reminderData: any) {
    this.reminderId = this.idService.generate();
    reminderData._id = this.reminderId;
    this._reminderId.next(this.reminderId);
    return this.httpClient.post<string>(SettingsService.settings.serverURL + '/reminder', reminderData, this.httpHeaders)
      .subscribe();
  }

/**************************************************
 * Send reminders response to the server
**************************************************/
  sendReminderResponseToServer(responseData: any) {
    return this.httpClient.post(SettingsService.settings.serverURL + '/reminderResponse', responseData, this.httpHeaders)
      .subscribe(result => {
          // console.log('reminder success', result);
        }, err => {
          console.log('reminder error: ', err);
        });
  }

/**************************************************
 * Send history/record to the server
**************************************************/
  uploadRecord(data: any) {
    return this.httpClient
      .post(SettingsService.settings.serverURL + '/history', data)
      .subscribe((resp) => {
        // console.log(resp);
      });
  }

/**************************************************
 * Send out of chair to the server
**************************************************/
  // var startTime = req.body.outofchair_startTime,
  // endTime = req.body.outofchair_endTime
  sendOutOfChairToServer(startTime: Date, endTime: Date) {
    return this.httpClient
      .post(SettingsService.settings.serverURL + '/outOfChair', {startTime, endTime} , this.httpHeaders)
      .subscribe((resp) => {
        // console.log('OutOfChair Success', resp);
      });
  }

  /**************************************************
   * Send a metrics to the server
  **************************************************/
  sendMetricsToServer(metricData: any) {
    return this.httpClient
      .post(SettingsService.settings.serverURL + '/metric', metricData, this.httpHeaders)
      .subscribe((resp) => {
        // console.log('Metrics Success', resp);
      });
    }
  /**************************************************
   * Send a notes to the server
  **************************************************/
  sendNotesToServer(noteData: any) {
  return this.httpClient
    .post(SettingsService.settings.serverURL + '/note', noteData, this.httpHeaders)
    .subscribe((resp) => {
      // console.log('Note Success', resp);
    });
  }

   /**************************************************
   * Send a settings to the server
  **************************************************/
    sendSettingsToServer(settingsData: any) {
      const settings = {
        settings_time: new Date().toISOString(),
        data: settingsData,
      };

      return this.httpClient
        .post(SettingsService.settings.serverURL + '/settings', settings, this.httpHeaders)
        .subscribe((resp) => {
        });
      }

   /**************************************************
   * Send a CLINICAL settings to the server
  **************************************************/
    sendClinicalSettingsToServer(patient: string, settingsData: any) {
      const settings = {
        patient,
        settings_time: new Date().toISOString(),
        data: settingsData,
      };

      return this.httpClient
        .post(SettingsService.settings.serverURL + '/settingsByClinician', settings, this.httpHeaders)
        .subscribe((resp) => {
        });
      }

  /**************************************************
   * Send a settings to the server
  **************************************************/
    sendLockSettingsToServer(settingsData: any) {
      return this.httpClient
        .post(SettingsService.settings.serverURL + '/lockSettings', settingsData, this.httpHeaders)
        .subscribe((resp) => {
        });
      }

  /**************************************************
   * Send a cached requests to the server
  **************************************************/
 async sendCachedHTTPRequests() {
    let successfullSends = 0;
    const requests = [];
    const storageKeys = Storage.keys().then(async keys => Promise.all(keys.keys.map(async key => {
      if (key.includes('reqcache')) {
        const request = await Storage.get({key}).then(value => {
          const requestValue = JSON.parse(value.value);
          requestValue.key = key;
          const timeString = +requestValue.key.split('-')[1];
          requestValue.time = new Date(timeString);
          if (DateHelpers.isOlderThan(requestValue.time, 60000)) {
            Storage.remove({key: requestValue.key});
          } else {
            requests.push(requestValue);
          }
        });
      }
    })));

    //NEED TO SEND IF DATE ORDER TO AVOID Weight shifts being called before reminders, ETC
    storageKeys.then(async () => {
      const requestsToSend = requests.sort((a, b) => {
        const dateA = new Date(a.time).getTime();
        const dateB = new Date(b.time).getTime();
        return dateA < dateB ? -1 : 1; // ? -1 : 1 for ascending/increasing order
      });

        for (const request of requestsToSend) {
          try{
          await this.postCachedRequest(request).then(
            (resp) => {
              Storage.remove({key: request.key});
              successfullSends += 1;
              (err) => {
                console.log('CACHE- cacherequest error', JSON.stringify(err));
                //this.presentToast(`Send Cache Error, ${JSON.stringify(err)}`);
                // if (err.statusText === 'Unknown Error') {
                //   Storage.remove({key:requestsToSend[i].key});
                // }
              };
            }).finally( () => {
              this.presentToast(`Sent Offline Shifts , ${successfullSends}/${requestsToSend.length}`);
            }
          );
          } catch (err) {
            if (err.error === 'duplicate error'){
              Storage.remove({key: request.key});
            }
          }
        }
      });
 }

 async postCachedRequest(requestToSend) {
     return await this.httpClient.post(requestToSend.url, requestToSend.body, this.httpHeaders).toPromise();
  }

  async clearHTTPCache(): Promise<any> {
    let count = 0;
    return new Promise(async resolve => {

      const storageKeys = await Storage.keys().then(async keys => Promise.all(keys.keys.map(async key => {
        if (key.includes('reqcache')) {
          Storage.remove({key});
          count++;
        }
      }))).then(() => {
        resolve(count);
      });
    });
  }


  /**************************************************
   * Offline Upload Saved Data
  **************************************************/
    sendLocalRecordingFiles() {
      try{
        this.localStorageService.getFilesFromDirectory(DATA_DIRS.mat_data_directory).then( f => {
          f.files.forEach(file => {
            this.localStorageService.readFileLocalStorage(DATA_DIRS.mat_data_directory, file).then(
              data => {
                if(data.length > 0){
                  this.sendMapsToServer(data, ['normal']).subscribe(
                    (res) => {
                      if (res) {
                        this.localStorageService.deleteLocalStorage(DATA_DIRS.mat_data_directory, file);
                      }
                    }
                  );
                } else {
                  this.localStorageService.deleteLocalStorage(DATA_DIRS.mat_data_directory, file);
                }
            });
          });
        });
      } catch (e) {
        console.log('No data in local storage: ', e);
      }
    }

    async presentToast(message: string) {
      const toast = await this.toastController.create({
        message,
        duration: 3500,
        position: 'top',

      });
      toast.present();
    }
}

/* eslint-disable no-underscore-dangle */
import { RecordingService } from './recording.service';
import { SettingsService } from 'src/app/settings/settings.service';
import { ShiftService } from 'src/app/map-tab/shift.service';
import { PressureMatService } from 'src/app/map-tab/pressure-mat/pressuremat.service';
import { MapData } from './../data-export.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatrixHelpers } from '../matrix-helpers';

@Injectable({
  providedIn: 'root',
})
export class ShiftDetectionService {
  copData: any[] = [];
  aggregatedPPI: any[] = [];
  triggeredSeatedActivity: any;
  triggeredSeatedActivityStartTime: Date;
  activityLabel: string;

  weightShiftTimeThreshold: number;
  startActivityBufferTime: Date;
  qualityWeightShiftTimeThreshold: number;
  seatActivityThresholdSeconds = 5;
  returnedToCenterCount: any;
  returnToCenterPoint: any;

  private freezePPI: boolean;
  private _isActivityDetected = new Subject<boolean>();
  private _isShiftDetected = new Subject<boolean>();
  private _isQualityShiftDetected = new Subject<boolean>();
  private _ppi = new Subject<any[]>();
  private _focusedPPI = new Subject<any[]>();
  private _topNppi = new Subject<any[]>();
  private isShiftDetected: boolean;
  private isQualityShiftDetected: boolean;
  private startPPIData;
  private lastDetectionTime: Date;
  private returnToCenterBuffer: number;
  private triggeringPPI = [];

  get isSActivityDetectedObs() {
    return this._isActivityDetected.asObservable();
  }
  get isShiftDetectedObs() {
    return this._isShiftDetected.asObservable();
  }
  get isQualityShiftDetectedObs() {
    return this._isQualityShiftDetected.asObservable();
  }

  get ppiObs() {
    return this._ppi.asObservable();
  }

  get topNppi() {
    return this._topNppi.asObservable();
  }

  get focusedPPIObs() {
    return this._focusedPPI.asObservable();
  }

  constructor(
    private pressureMatService: PressureMatService,
    private shiftService: ShiftService,
    private recordingService: RecordingService
  ) {
    this.aggregatedPPI = [];
    this.copData = [];

    this.weightShiftTimeThreshold = 15 * 1000; // 15 seconds
    this.qualityWeightShiftTimeThreshold = 60 * 1000; // 60 seconds
    this.returnedToCenterCount = 0;
    this.triggeredSeatedActivity = false;

    this.isShiftDetected = false;
    this.isQualityShiftDetected = false;
    this.startPPIData = [];
    this.freezePPI = false;
    this.returnToCenterBuffer = 2;
  }

  getPPIData(mapData) {
    const ppi = this.pressureMatService.getAllPressureIndexMatrix(mapData);
    ppi.sort((a, b) => (a.ppi > b.ppi ? 1 : -1)).reverse();
    this._ppi.next(ppi);
    return ppi;
  }

  aggregatePPI(ppi) {
    if (this.aggregatedPPI.length > 1) {
      this.aggregatedPPI.map((item, index) => {
        item.ppi.map((p, i) => {
          //p.change = (this.aggregatedPPI[index - 1].ppi[i].ppi - p.ppi) / this.aggregatedPPI[index - 1].ppi[i].ppi;
          p.date = item.date;
        });
      });
    }
    this.aggregatedPPI.push({ ppi, date: new Date() });
  }

  filterByReference(arr1, arr2){
    let res = [];
    res = arr1.filter((el) =>
      arr2.find((element) => element.x === el.x && element.y === el.y)
    );
    return res;
  }

  ppiShiftTriggered(topNPPI){
    const targetRegions = [];
    this.aggregatedPPI.forEach((element) => {
      targetRegions.push(this.filterByReference(element.ppi, topNPPI));
    });

    const flatRegions = targetRegions.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );

    const ppiRegionSamples = flatRegions.reduce((obj, value) => {
      const key = `region(${value.x},${value.y})`;
      if (obj[key] == null) {
        obj[key] = [];
      }
      obj[key].push(value);
      return obj;
    }, {});

    this._topNppi.next(ppiRegionSamples);

    for (const key in ppiRegionSamples) {
      if (!ppiRegionSamples.hasOwnProperty(key)) {
        continue;
      }

      const region = ppiRegionSamples[key];
      const min = Math.min.apply(
        null,
        region.map((item) => item.ppi)
      );
      const max = Math.max.apply(
        null,
        region.map((item) => item.ppi)
      );

      let percentDiff = 0;
      // calculate the percent difference;
      if (max > 0) {
        percentDiff = (max - min) / max;
      }
      if (percentDiff > SettingsService.settings.detectionPPIThreshold / 100) {
        // trigger possible shift in ppi
        return true;
      }
    }
    return false;
  }

  calculateCentroidData(mapData) {
    const centroidData = this.pressureMatService.getCentroid(mapData);
    this.copData.push({ date: mapData.date, centroidData });
    return centroidData;
  }

  calculateCentroidChange(copData) {
    // COP MOVEMENT
    const xData = copData.map((x) => x.centroidData.location.x);
    const yData = copData.map((x) => x.centroidData.location.y);
    const maxXChange = MatrixHelpers.findMaxDiff(xData, xData.length);
    const maxYChange = MatrixHelpers.findMaxDiff(yData, yData.length);
    return { maxXChange, maxYChange };
  }

  calculateStartingPPI(topNPPI) {
    // Deal with starting PPI
    const startPPIArray = [];
    this.aggregatedPPI.forEach((element) => {
      startPPIArray.push(this.filterByReference(element.ppi, topNPPI));
    });

    const flatPPIRegions = startPPIArray.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );

    const focusedPpiRegionSamples = flatPPIRegions.reduce((obj, value) => {
      const key = `region(${value.x},${value.y})`;
      if (obj[key] == null) {
        obj[key] = [];
      }
      obj[key].push(value);
      return obj;
    }, {});

    this._focusedPPI.next(focusedPpiRegionSamples);
  }

  detectShifts(mapData: MapData) {
     // check if it has been more than 5 seconds since the last detection
     if (this.lastDetectionTime) {
      const timeDiff = new Date().getTime() - this.lastDetectionTime.getTime();
      if (timeDiff < 5000) {
        this.aggregatedPPI = [];
        this.copData = [];
      }
    }

    let startPPIActivity = false;
    let startCOPActivity = false;
    let topNPPI = [];

    //COP MOVEMENT
    const centroidData = this.calculateCentroidData(mapData);
    const centroidChange = this.calculateCentroidChange(this.copData);

    if (centroidChange.maxXChange >= 1 || centroidChange.maxYChange >= 1) {
      startCOPActivity = true;
    }

    //PPI MOVEMENT DETECTION
    const ppi = this.getPPIData(mapData);

    //If any Aggregated PPI data
    this.aggregatePPI(ppi);

    if (this.aggregatedPPI.length > this.seatActivityThresholdSeconds) {
      const startValues = this.aggregatedPPI[0].ppi;
      topNPPI = startValues.slice(0, SettingsService.settings.detectionPPICount);
      startPPIActivity = this.ppiShiftTriggered(topNPPI);
    }

    // Check shift detection
    if (startPPIActivity && startCOPActivity) {
      this.returnedToCenterCount = 0;
    }

    // Trigger seated activity detected
    if (startPPIActivity && startCOPActivity && !this.triggeredSeatedActivity ) {
      this.triggeredSeatedActivity = true;
      this.returnToCenterPoint = this.copData[0];
      this.triggeredSeatedActivityStartTime = new Date();
      this._isActivityDetected.next(true);
      this.startPPIData = [...topNPPI];
    }

    // Deal with starting PPI
    this.calculateStartingPPI(topNPPI);

    // Trigger short weight starting shift detected
    if (
      !this.isShiftDetected &&
      this.triggeredSeatedActivity &&
      new Date().getTime() - this.triggeredSeatedActivityStartTime.getTime() >
        this.weightShiftTimeThreshold
    ) {
      this.isShiftDetected = true;
      this._isShiftDetected.next(true);
    }

    // Trigger mid weight shift detected
    if (
      !this.isQualityShiftDetected &&
      this.triggeredSeatedActivity &&
      new Date().getTime() - this.triggeredSeatedActivityStartTime.getTime() >
        this.qualityWeightShiftTimeThreshold
    )
    {
      this.isQualityShiftDetected = true;
      this._isQualityShiftDetected.next(true);
      this.shiftService._isShift.next(true);
    }

    if ( // quality shift finished
      this.isQualityShiftDetected &&
      this.triggeredSeatedActivity &&
      new Date().getTime() - this.triggeredSeatedActivityStartTime.getTime() >
        SettingsService.settings.shiftDuration * 1000
    ) {
      this.endDetection();
    }

    // END ACTIVITY DETECTION
    if (this.returnToCenterPoint && this.triggeredSeatedActivity) {
      if (
        centroidData.location.x >=
          this.returnToCenterPoint.centroidData.location.x - 2 &&
        centroidData.location.x <=
          this.returnToCenterPoint.centroidData.location.x + 2 &&
        centroidData.location.y >=
          this.returnToCenterPoint.centroidData.location.y - 2 &&
        centroidData.location.y <=
          this.returnToCenterPoint.centroidData.location.y + 2
      ) {
        this.returnedToCenterCount += 1;
      } else {
        this.returnedToCenterCount = 0;
      }
      if (this.returnedToCenterCount >= 5) {
        this.endDetection();
      }
    }
    this.shiftPPIData();
  }


  shiftPPIData() {
    if (this.copData.length > this.seatActivityThresholdSeconds) {
      this.copData.shift();
    }
    if (this.aggregatedPPI.length > this.seatActivityThresholdSeconds) {
      this.aggregatedPPI.shift();
    }
  }

  streamPPI(mapData) {
    let topNPPI = [];

    //PPI MOVEMENT DETECTION
    const ppi = this.getPPIData(mapData);

    //If any Aggregated PPI data
    this.aggregatePPI(ppi);

    if (this.aggregatedPPI.length > this.seatActivityThresholdSeconds) {
      const startValues = this.aggregatedPPI[0].ppi;
      topNPPI = startValues.slice(0, SettingsService.settings.detectionPPICount);
    }
    // Deal with starting PPI
    if (!this.freezePPI) {
      this.startPPIData = [...topNPPI];
    }
    this.ppiShiftTriggered(this.startPPIData);
    this.calculateStartingPPI(this.startPPIData);
    this.shiftPPIData();
  }

  toggleFreezePPI() {
    this.freezePPI = !this.freezePPI;
  }

  fullDetectShifts(mapData: MapData, buffer: any[]) {
    this.seatActivityThresholdSeconds = SettingsService.settings.detectionDuration;
    if (SettingsService.settings.pressureReliefTechnique !== 'manual') {
      this.returnToCenterBuffer = 2;
    } else {
      this.returnToCenterBuffer = 2;
    }
    // check if it has been more than 5 seconds since the last detection
    if (this.lastDetectionTime) {
      const timeDiff = new Date().getTime() - this.lastDetectionTime.getTime();
      if (timeDiff < 5000) {
        this.aggregatedPPI = [];
        this.copData = [];
      }
    }
    let startPPIActivity = false;
    let startCOPActivity = false;
    let topNPPI = [];

    // COP MOVEMENT
    const centroidData = this.pressureMatService.getCentroid(mapData);
    this.copData.push({ date: mapData.date, centroidData });

    const xData = this.copData.map((x) => x.centroidData.location.x);
    const yData = this.copData.map((x) => x.centroidData.location.y);
    const maxXChange = MatrixHelpers.findMaxDiff(xData, xData.length);
    const maxYChange = MatrixHelpers.findMaxDiff(yData, yData.length);

    if (maxXChange >= 1) {
      startCOPActivity = true;
    }
    if (maxYChange >= 1) {
      startCOPActivity = true;
    }

      // PPI SHIFT DETECTION
      const ppi = this.pressureMatService.getAllPressureIndexMatrix(mapData);
      ppi.sort((a, b) => (a.ppi > b.ppi ? 1 : -1)).reverse();
      this._ppi.next(ppi);

    if (this.aggregatedPPI.length > 1) {
      this.aggregatedPPI.map((item, index) => {
        item.ppi.map((p, i) => {
          //p.change = (this.aggregatedPPI[index - 1].ppi[i].ppi - p.ppi) / this.aggregatedPPI[index - 1].ppi[i].ppi;
          p.date = item.date;
        });
      });
    }
    this.aggregatedPPI.push({ ppi, date: new Date() });

    if (this.aggregatedPPI.length > 5) {
      const startValues = this.aggregatedPPI[0].ppi;
      topNPPI = startValues.slice(0, SettingsService.settings.detectionPPICount);

      const targetRegions = [];
      this.aggregatedPPI.forEach((element) => {
        targetRegions.push(this.filterByReference(element.ppi, topNPPI));
      });

      const flatRegions = targetRegions.reduce(
        (accumulator, value) => accumulator.concat(value),
        []
      );

      const ppiRegionSamples = flatRegions.reduce((obj, value) => {
        const key = `region(${value.x},${value.y})`;
        if (obj[key] == null) {
          obj[key] = [];
        }
        obj[key].push(value);
        return obj;
      }, {});

      this._topNppi.next(ppiRegionSamples);

      for (const key in ppiRegionSamples) {
        if (!ppiRegionSamples.hasOwnProperty(key)) {
          continue;
        }

        const region = ppiRegionSamples[key];
        const min = Math.min.apply(
          null,
          region.map((item) => item.ppi)
        );
        const max = Math.max.apply(
          null,
          region.map((item) => item.ppi)
        );

        let percentDiff = 0;
        // calculate the percent difference;
        if (max > 0) {
          percentDiff = (max - min) / max;
        }
        if (percentDiff > SettingsService.settings.detectionPPIThreshold / 100) {
          // trigger possible shift in ppi
          startPPIActivity = true;
          //check if the region is already in the list
          if (!this.triggeringPPI.find((x) => x.x === region[0].x && x.y === region[0].y)) {
            const triggerRegion = {
              x: region[0].x,
              y: region[0].y,
              min,
              max,
              percentDiff,
            };
            const freezeObj = Object.freeze(triggerRegion);  //add this line before your console.log
            // console.log('FREEZE OBJ", freezeObj);
            this.triggeringPPI.push(freezeObj);
          }
        }
      }
    }

    let activityTrigger = startPPIActivity && startCOPActivity;
    if (SettingsService.settings.pressureReliefTechnique !== 'manual') {
      activityTrigger = startPPIActivity;
    }
    // Trigger seated activity detected
    if (activityTrigger && !this.triggeredSeatedActivity ) {
      this.triggeredSeatedActivity = true;
      this.returnToCenterPoint = this.copData[0];
      this.triggeredSeatedActivityStartTime = new Date();
      this.recordingService.startShiftRecording('automatic', buffer);
      this._isActivityDetected.next(true);

      this.startPPIData = [...topNPPI];

       //show retern to center region
       this.pressureMatService.drawSingleCell(
        'calibrateCanvas',
        this.returnToCenterPoint.centroidData.location.x - (this.returnToCenterBuffer + 0.5),
        this.returnToCenterPoint.centroidData.location.y -  (this.returnToCenterBuffer + 0.5)
        , 'pink', this.returnToCenterBuffer * 2, this.returnToCenterBuffer * 2, 3);
    }

    // Deal with starting PPI
    const currentPPIArray = [];
      this.aggregatedPPI.forEach((element) => {
        currentPPIArray.push(this.filterByReference(element.ppi, this.startPPIData));
      });

      const flatPPIRegions = currentPPIArray.reduce(
        (accumulator, value) => accumulator.concat(value),
        []
      );

      const focusedPpiRegionSamples = flatPPIRegions.reduce((obj, value) => {
        const key = `region(${value.x},${value.y})`;
        if (obj[key] == null) {
          obj[key] = [];
        }
        obj[key].push(value);
        return obj;
      }, {});

    this._focusedPPI.next(focusedPpiRegionSamples);

    // Trigger (15 sec) weight shift detected
    if (
      // startPPIActivity &&
      // startCOPActivity &&
      !this.isShiftDetected &&
      this.triggeredSeatedActivity &&
      new Date().getTime() - this.triggeredSeatedActivityStartTime.getTime() >
        this.weightShiftTimeThreshold
    ) {
      this.isShiftDetected = true;
      this._isShiftDetected.next(true);
    }

    // Trigger quality weight shift detected
    if (
      !this.isQualityShiftDetected &&
      this.triggeredSeatedActivity &&
      new Date().getTime() - this.triggeredSeatedActivityStartTime.getTime() >=
        this.qualityWeightShiftTimeThreshold
    )
    {
      this.isQualityShiftDetected = true;
      this._isQualityShiftDetected.next(true);
      this.shiftService._isShift.next(true);
    }

    if ( // quality shift finished
      this.isQualityShiftDetected &&
      this.triggeredSeatedActivity &&
      new Date().getTime() - this.triggeredSeatedActivityStartTime.getTime() >=
        SettingsService.settings.shiftDuration * 1000
    ) {
      this.endDetection();
    }


    // PPI in top N is above threshold
    const ppiReturn = [];
    const topPPIInDetection = currentPPIArray[currentPPIArray.length - 1];
    topPPIInDetection.forEach(element => {
      const result = this.triggeringPPI.filter((v, i) => (v.x === element.x && v.y === element.y));
      if (result.length > 0) {
        const goalPressure = result[0].max * ( 1 - (SettingsService.settings.detectionPPIThreshold / 100));
        const newPPIObj = {
          x: element.x,
          y: element.y,
          currentPPi: element.ppi,
          goalPressure,
          min: result[0].min,
          max: result[0].max,
          triggerDiff: result[0].percentDiff,
        };
        ppiReturn.push(newPPIObj);
      }
    });

    // any of the top 5 ppi returns to above trigger pressure
    const isPPIAboveGoal = ppiReturn.some(el => el.currentPPi > el.goalPressure);
    //TODO: check to see if this should be every ppi
    // END ACTIVITY DETECTION
    if (this.returnToCenterPoint && this.triggeredSeatedActivity) {
       // COP is in return trigger section
        const isCOPInCenter = centroidData.location.x >=
          this.returnToCenterPoint.centroidData.location.x - this.returnToCenterBuffer &&
        centroidData.location.x <=
          this.returnToCenterPoint.centroidData.location.x + this.returnToCenterBuffer &&
        centroidData.location.y >=
          this.returnToCenterPoint.centroidData.location.y - this.returnToCenterBuffer &&
        centroidData.location.y <=
          this.returnToCenterPoint.centroidData.location.y + this.returnToCenterBuffer;
      if (isCOPInCenter && isPPIAboveGoal) {
        this.returnedToCenterCount += 1;
      } else {
        this.returnedToCenterCount = 0;
      }
      if (this.returnedToCenterCount >= 5) {
        this.endDetection();
      }
    }

    if (this.copData.length > this.seatActivityThresholdSeconds) {
      this.copData.shift();
    }
    if (this.aggregatedPPI.length > this.seatActivityThresholdSeconds) {
      this.aggregatedPPI.shift();
    }
  }

  endDetection() {
    this.pressureMatService.clearSingleCells('calibrateCanvas', 0, 0, 16 );
    this.lastDetectionTime = new Date();
    this.triggeredSeatedActivity = false;
    this.startActivityBufferTime = null;
    this._isActivityDetected.next(false);
    this._isShiftDetected.next(false);
    this._isQualityShiftDetected.next(false);
    this.returnedToCenterCount = 0;
    this.shiftService._isShift.next(false);

    this.isShiftDetected = false;
    this.isQualityShiftDetected = false;
    this.triggeringPPI = [];
  }
}


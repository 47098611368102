/* eslint-disable no-underscore-dangle */
import { DateHelpers } from 'src/app/graphs/date-helper';
import { ShiftDetectionService } from './map-tab/shift-detection.service';
import { DataExportService } from './data-export.service';
import { SettingsService } from 'src/app/settings/settings.service';
import { Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { NetworkService } from './network.service';
import { Capacitor, LocalNotification, LocalNotificationPendingList, Plugins } from '@capacitor/core';
import { SoundsService } from './sounds.service';
import { NotificationChannel } from '@capacitor/core';

const { Storage, LocalNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {

  appStateSub: Subscription;
  appState: string;
  reminderId: string;
  networkSub: any;
  networkType: string;
  shiftAlertShown = false;
  reminderNotificationIds = [];
  reminderActivitySub: Subscription;
  dismissTimeout;
  reminderAlert: HTMLIonAlertElement;
  reminderAlertPresented: boolean;

  constructor(
    private alertController: AlertController,
    private settingsService: SettingsService,
    private dataExportService: DataExportService,
    private networkService: NetworkService,
    private shiftDetectionService: ShiftDetectionService,
    private soundService: SoundsService,
    ) {
      this.networkType = 'none';
      //---------- Network connection ----------//
    this.networkSub = this.networkService.networkTypeObs.subscribe(networkType => {
      this.networkType = networkType;
    });

      this.appStateSub = this.settingsService.appState
      .subscribe(state => {
        this.appState = state;
      });

       //reminder id sub
    this.dataExportService.reminderIdObs.subscribe(reminderId => {
      this.reminderId = reminderId;
    });
    }

  async clearAllNotification() {
   await LocalNotifications.getPending().then(notifs => {
     LocalNotifications.cancel(notifs);
     });
     if (Capacitor.platform === 'android') {
      this.clearChannels();
    }
  }

  async clearNotification(id: any) {
    await LocalNotifications.getPending().then(notifs => {
      if (notifs.notifications.length > 0) {
        const targetNotif = notifs.notifications.find(n => n.id === id);
        if (targetNotif) {
          let cancelNotif = {
            notifications: [targetNotif]
          }
          LocalNotifications.cancel(cancelNotif);
        }
      }
    });
    if (Capacitor.platform === 'android') {
      this.clearChannels();
    }
}

  async clearReminderNotifications() {
    await LocalNotifications.getPending().then(notifs => {
      LocalNotifications.cancel(notifs);
      });
      if (Capacitor.platform === 'android') {
        this.clearChannels();
      }
  };

  async clearChannels() {
    await LocalNotifications.listChannels()
    .then(channels => {
      channels.channels.forEach(channel => {
        LocalNotifications.deleteChannel(channel)
      })
    })
  }

  async androidNotificationSetup(title: string, text: string, time: Date, id: number, every?: any) {
    let channel1: NotificationChannel;
    const uniqueChannelId = 'test' + Math.floor(Math.random() * 100000);
    const soundOption = SettingsService.settings.soundOnNotification ? `./public/assets/sounds/${SettingsService.settings.notificationSoundPath}` : 'default'


    await LocalNotifications.listChannels()
    .then(channels => {
      channels.channels.forEach(channel => {
        LocalNotifications.deleteChannel(channel)
      })
    })
    .finally(() => {
        // Creating channel example //
      channel1 = {
        id: uniqueChannelId,
        name: uniqueChannelId,
        importance: 5,
        description: 'a unique channel',
        sound: soundOption,
        visibility: 1,
        vibration: true
      }
    });
    await LocalNotifications.createChannel(channel1);

    let trigger = {};
    if (every) {
      trigger = {at : time, every};
    } else {
      trigger = {at : time};
    }

    await LocalNotifications.schedule({
      notifications: [
        {
          title,
          body: text,
          id,
          channelId: uniqueChannelId,
          //remove type error
          schedule: trigger,
          attachments: null,
          actionTypeId: '',
          extra: null
        }
      ]
    });
  }

  async iosNotificationSetup(title: string, text: string, time: Date, id: number, every?: any) {
    let trigger = {};
    if (every) {
      trigger = {at : time, every};
    } else {
      trigger = {at : time};
    }
    const soundOption = SettingsService.settings.soundOnNotification ? `./public/assets/sounds/${SettingsService.settings.notificationSoundPath}` : 'default'

    await LocalNotifications.schedule({
      notifications: [
        {
          title,
          body: text,
          id,
          //remove type error
          schedule: trigger,
          attachments: null,
          actionTypeId: '',
          sound: soundOption,
          extra: null
        }
      ]
    });
  }

  async sendLocalNotification(title: string, text: string, time: Date, id: number, every?: any){
    if (Capacitor.platform === 'android') {
      await this.androidNotificationSetup(title, text, time, id, every);
    } else if (Capacitor.platform === 'ios') {
      await this.iosNotificationSetup(title, text, time, id, every);
    }
  }

  async presentShiftReminderConfirm(directionStringArray: string[], periodPPI) {
    const shiftTime = SettingsService.settings.shiftDuration;
    const dismissTime = Math.floor((SettingsService.settings.shiftInterval * 1000) / 2);
    const alertCreateTime = new Date();
    this.logReminder(alertCreateTime);

    let lastShiftTime = '0';
    let lastShiftString = 'No previous shift';
    const lastShit = await Storage.get({ key: 'lastShift' });
    if (lastShit && lastShit.value){
      lastShiftTime = lastShit.value;
    }
    //calculate time since last shift
    if (lastShiftTime !== '0') {
      const shiftTimeDate = new Date(lastShiftTime);
      const now = new Date();
      const timeDiff = DateHelpers.getDateDiff(shiftTimeDate, now);
      const hoursString = timeDiff.hour < 10 ? '0' + timeDiff.hour.toString() : timeDiff.hour.toString();
      const minutesString = timeDiff.minute < 10 ? '0' + timeDiff.minute.toString() : timeDiff.minute.toString();
      const secondString = timeDiff.second < 10 ? '0' + timeDiff.second.toString() : timeDiff.second.toString();
      lastShiftString = `Time Since Last Shift:
      ${hoursString}:${minutesString}:${secondString}`;
    }
    const periodPPIString = `Front:${periodPPI.frontPPI}<br>
                              Back:${periodPPI.backPPI}<br>
                              Left:${periodPPI.leftPPI}<br>
                              Right:${periodPPI.rightPPI}`;

    if (this.reminderAlertPresented) {
      this.logReminderResponse(this.reminderId, 'ignored');
    } else {
      const frontLeftColor = this.cssColorFromValue(periodPPI.q2PPI);
      const frontRightColor = this.cssColorFromValue(periodPPI.q1PPI);
      const backLeftColor = this.cssColorFromValue(periodPPI.q3PPI);
      const backRightColor = this.cssColorFromValue(periodPPI.q4PPI);
      this.reminderAlertPresented = true;
      this.reminderAlert = await this.alertController.create({
        header: `Shift to ${directionStringArray} sections`,
        subHeader: `${lastShiftString}`,
        message: `It is time for ${shiftTime} second <strong>weight shift</strong>!<br>` +
          `<div class="suggestion-container">` +
          `<div class=` + frontLeftColor + `>Front Left</div>` +
          `<div class=` + frontRightColor + `>Front Right</div>` +
          `<div class=` + backLeftColor + `>Back Left</div>` +
          `<div class=` + backRightColor + `>Back Right</div>` +
        `</div>`,
        backdropDismiss: true, //do not allow clicking outside of alert
        buttons: [
          {
            text: 'Okay',
            handler: () => {
              //this.shiftService.cycleWeightShift(shiftTime, 'reminder');
              this.logReminderResponse(this.reminderId, 'ok');
              if (this.dismissTimeout) {
                clearTimeout(this.dismissTimeout);
              }
                this.logReminderResponse(this.reminderId, 'ok');
                this.reminderAlert.dismiss();
                this.reminderAlertPresented = false;
                if (this.reminderActivitySub) {
                  this.reminderActivitySub.unsubscribe();
                }
              //this.router.navigateByUrl('/tabs/map');
            }
          },
        ]
      });

      await this.reminderAlert.present();

      // if after alert user begins to shift
      this.reminderActivitySub = this.shiftDetectionService.isSActivityDetectedObs.subscribe(isActivityDetected => {
        if (isActivityDetected) {
          if (this.dismissTimeout) {
            clearTimeout(this.dismissTimeout);
          }
          this.logReminderResponse(this.reminderId, 'ok');
          this.reminderAlertPresented = false;
          this.reminderAlert.dismiss();
          if (this.reminderActivitySub) {
            this.reminderActivitySub.unsubscribe();
          }
        }
      });

      // if user does nothing after alert for 2 minutes
      this.dismissTimeout = setTimeout(() => {
        this.reminderAlert.dismiss();
        this.logReminderResponse(this.reminderId, 'ignored');
        if (this.reminderActivitySub) {
          this.reminderActivitySub.unsubscribe();
        }
        this.reminderAlertPresented = false;
        //restart sitting timer
        // this.shiftService._isShift.next(false);
      }, dismissTime); // dismiss time is half the time to next shift
    }
  }

  logReminder(alertCreateTime: Date) {
    const reminderData = {reminder_time: alertCreateTime, reminder_type: this.appState};
    this.dataExportService.sendReminderToServer(reminderData);
  }

  cssColorFromValue(value: number) {
    let color = 'suggestion-box-green';
    if (value < 67) {
      color = 'suggestion-box-green';
    } else if (value >= 67 && value < 134) {
      color = 'suggestion-box-orange';
    } else if (value >= 134) {
      color = 'suggestion-box-red';
    }
    return color;
  }

  logReminderResponse(reminderId: string, type: string) {
    const responseData = {reminder: reminderId, response_time: new Date(), response_type: type};
    this.dataExportService.sendReminderResponseToServer(responseData);
  }

  async presentUnpromptedShiftAlertConfirm(
    context: object,
    confirmAction: () => {}
  ) {
    const shiftTime = SettingsService.settings.shiftDuration;
    const alert = await this.alertController.create({
      header: 'AWSHIFT!',
      message: `Would you like to do a ${shiftTime} second <strong>weight shift</strong>?`,
      backdropDismiss: true, //do not allow clicking outside of alert
      buttons: [
        {
          text: 'Okay',
          handler: confirmAction.bind(context)
        },
        {
          text: 'Cancel',
          role: 'cancel',
          handler: (blah) => {
          }
        }
      ]
    });
    await alert.present();
  }

  setNextShiftNotification(startTime: number, shiftInterval: number) {
    const notifiactionTime = new Date(startTime + (shiftInterval * 1000));
    // Send Notification
    if (SettingsService.settings.showNotifications) {
      this.sendLocalNotification(
        'Awshift',
        'Its time to shift: ' + notifiactionTime,
        notifiactionTime,
        1, // id 1 = shift notifications
        false
      );
    }
    // set the notificiation timer even if notification not set to keep track of timers
    Storage.set({
      key: 'next_notification',
      value: notifiactionTime.toUTCString(),
    });
  }

  setAllDayNotifications(startTime: number, shiftInterval: number) {
    try{
      let endTime = new Date(SettingsService.settings.dailyStopTime);
      const endHours = endTime.getHours();
      const endMins = endTime.getMinutes();
      endTime = new Date();
      endTime = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate(), endHours, endMins, 0, 0);
      // only set next 20 notifications if they are there
      // skip first one as it is the current notification
      const allNotificationIntervals = DateHelpers.intervals(new Date(startTime), endTime, shiftInterval).slice(0,21);
      console.log('allNotificationIntervals', allNotificationIntervals);
      this.reminderNotificationIds = [];
      let notificationIdIncrement = 100;
      this.clearAllNotification().then(resloved => {
        if (SettingsService.settings.showNotifications) {
          for (const interval of allNotificationIntervals) {
            const notifTime = new Date(interval);
            this.sendLocalNotification(
              'Awshift',
              'Its time to shift: ' + notifTime.toLocaleTimeString(),
              notifTime,
              notificationIdIncrement, // id 100+ = shift notifications
              false
            );
            this.reminderNotificationIds.push(notificationIdIncrement);
            notificationIdIncrement++;
          }
        }
      }, rejected => {
        console.log('collection rejected notifications: ', rejected);
      });
    } catch (e) {
      console.log('collection error notifications: ', e);
    }
  }

  async showError(error, message) {
    const alert = await this.alertController.create({
      header: error,
      subHeader: message,
      buttons: ['OK']
    });
    await alert.present();
  }
}
